import React, { useCallback, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Card, Container, Alert, Button, Col, Form, Row, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SnackbarAlert } from "../../../../components/SnackbarAlert.js";
import moment from "moment";
import { Search } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import api from "../../../../service/api.js";
import { Lists } from "./ListSalesSummary.js";
import { tableColumns } from "./Data.js";
import download from "downloadjs";
import { DatePicker } from "antd";

const SalesSummaryByDocumentType = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [isValidDateRange, setIsValidDateRange] = useState(true);

    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    // const [toDatePickerValue, setToDatePickerValue] = useState(moment().format('YYYY-MM'));
    // const [fromDatePickerValue, setFromDatePickerValue] = useState(moment().format('YYYY-MM'));
    const [toDatePickerValue, setToDatePickerValue] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [fromDatePickerValue, setFromDatePickerValue] = useState(moment().startOf('month').format('YYYY-MM-DD'));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const searchFields = {
        start_date: fromDatePickerValue,
        end_date: toDatePickerValue,
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getSalesSummaryByDocumentType(searchParam);
    };
    
    const getSalesSummaryByDocumentType = useCallback(async (search) => {
        setLoading(true);
        try {
            const response = await api.get("tms/invoice/sales/summary-by-document-type", search);
            // console.log("response data:", response)
            setData(response);
            setError();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }

        setLoading(false);
    }, []);

    const getInputValue = (value) => {
        // return value != '' ? value : moment().format("YYYY-MM-DD");
        return value != '' ? moment(value).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    }

    const generateExcelFile = async (search) => {
        setIsSubmitting(true);
        try {
            const response = await api.getexcel(
                "tms/invoice/sales/summary-by-document-type/export",
                search
            );
            // console.log("GENERATED EXCEL: ", response);

            if (response.type.includes('application/json')) {
                const jsonData = JSON.parse(await response.text());
                
                if (jsonData.success){
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                }
                else{
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
            }else if (response.size !== 0) {
                // console.log(response);
                download(response, "Sales_summary_by_document_type_"+search.start_date+"_to_"+search.end_date, "application/xlsx");
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error.message);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        getSalesSummaryByDocumentType(searchValue);
    }, [getSalesSummaryByDocumentType, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log("Search: ", searchValue);

        var start_date = new Date(searchValue.start_date);
        var end_date = new Date(searchValue.end_date);

        setIsValidDateRange(start_date <= end_date);
    },[searchValue]);

    return (
        <React.Fragment>
            <Helmet title="Reports > Sales summary by document type" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Sales summary by document type</h1>
                <Card>
                    <Card.Header>
                       {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}

                        <Col md={5} className="mb-2">
                            <Row className="flex-row ms-md-1">
                                <Col md={4} className="p-md-0">
                                    <Form.Group>
                                        <DatePicker
                                            id="from-date-picker"
                                            name="from_date"
                                            className={`form-control`}
                                            // disabledDate={d => !d || moment(d).isAfter(moment()) || moment(d).isBefore(`${minSelectableYear}`)}
                                            // picker="year"
                                            placeholder="From date"
                                            defaultValue={moment().startOf('month')}
                                            allowClear={false}
                                            onChange={(date) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    start_date: getInputValue(date ?? ''), //event.target.value != '' ? event.target.value : moment().format("YYYY-MM"),
                                                });
                                            }}
                                            style={{minHeight: '2.5rem'}}
                                        />
                                    </Form.Group>

                                    {/* <Form.Control
                                        type="month"
                                        name="start_date"
                                        // dateFormat="yyyy-MM-dd"
                                        defaultValue={moment().format("YYYY-MM")}
                                        min={`${minSelectableYear}-01`}
                                        max={`${new Date().getFullYear() + 1 }-12`}
                                        size="lg"
                                        onChange={(event) => {
                                            setSearchValue({
                                                ...searchValue,
                                                start_date: getInputValue(event?.target?.value ?? ''), //event.target.value != '' ? event.target.value : moment().format("YYYY-MM"),
                                            });
                                        }}
                                    /> */}
                                </Col>
                                <Col md={1} className="d-flex align-items-center justify-content-center m-0 p-0">
                                    <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                        To
                                    </Badge>
                                </Col>
                                <Col md={4} className="p-md-0">
                                    <Form.Group >
                                        <DatePicker
                                            id="to-date-picker"
                                            name="to_date"
                                            // disabledDate={d => !d || moment(d).isAfter(moment(fromDatePickerValue).add(maxYearRange, 'years')) || moment(d).isBefore(moment(fromDatePickerValue).subtract(maxYearRange, 'years')) || moment(d).isBefore(`${minSelectableYear}`) || moment(d).isAfter(moment())}
                                            // picker="year"
                                            className={`form-control`}
                                            placeholder="To date"
                                            defaultValue={moment().endOf('month')}
                                            allowClear={false}
                                            onChange={(date) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    end_date: getInputValue(date ?? ''),
                                                });
                                            }}
                                            style={{minHeight: '2.5rem'}}
                                        />
                                    </Form.Group>
                                    {/* <Form.Control
                                        type="month"
                                        name="end_date"
                                        defaultValue={moment().format("YYYY-MM")}
                                        min={`${minSelectableYear}-01`}
                                        max={`${new Date().getFullYear() + 1}-12`}
                                        size="lg"
                                        onChange={(event) => {
                                            console.log("This is end date: ", event.target.value)
                                            setSearchValue({
                                                ...searchValue,
                                                end_date: getInputValue(event?.target?.value ?? ''),
                                            });
                                        }}
                                    /> */}
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center justify-content-md-start mt-2 mt-md-0">
                                        <Button
                                            className="float-start me-1"
                                            variant="info"
                                            type="submit"
                                            size="lg"
                                            onClick={() => search()}
                                            disabled={!isValidDateRange}
                                        >
                                            <Search className="feather" />
                                        </Button>

                                        {!isSubmitting && (
                                            <Button
                                                className="float-end me-1"
                                                variant="info"
                                                type="submit"
                                                size="lg"
                                                onClick={() =>
                                                    generateExcelFile(searchValue)
                                                }
                                                disabled={!isValidDateRange}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileExcel}
                                                />
                                            </Button>
                                        )}

                                        {isSubmitting && (
                                            <div className="spinner-border" role="status" style={{ minWidth: '2rem', minHeight: '2rem' }}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                    </Card.Header>
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={data}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default SalesSummaryByDocumentType;
