import { Formik} from "formik";
import * as Yup from "yup";
import React, { useState, useEffect, useRef } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import StudentList from "./StudentList";
import { TableColumns } from "./TableColumns";
import { FileUpload } from "../../../../../components/FileUpload";
import api from "../../../../../service/api";
import { ArrowLeft } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../../utils/utility";

const FormRow = ({ invoiceStudent, invoiceId, invoice, setNotif, formConfig, setLoading }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;

    // eslint-disable-next-line
    const [inputFields, setInputFields] = useState({
        type: "",
        amount: "",
        remarks: "",
        admin_fee: "",
        test_fee: "",
        bca_fee: "",
    });

    const [cnAmount, setCNAmount] = useState(0);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUserAmount, setSelectedUserAmount] = useState();
    const [studentType, setStudentType] = useState(false);
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCancelCourse, setIsCancelCourse] = useState(false);
    const amountInputRef = useRef(null);

    useEffect(()=>{
        if (!studentType)
            setSelectedUser(null);
    }, [studentType])

    const schema = Yup.object().shape({
        type: Yup.string().required('Type is required'),
        reason: (invoice?.status_key.toUpperCase() !== 'PENDING') ? Yup.string().required("Reason is required for " + invoice.status.toLowerCase() + " invoices") : Yup.string(),
    });

    // const paidAmount = (invoice.total_amount - invoice.remaining_amount).toFixed(2);
    const InvoiceAmount = invoice.total_amount;

    const handleAmountChange = (event) => {
        const { name, value } = event.target;

        // console.log(value);
        if (/^\d+(\.\d+)?$/.test(value) && parseFloat(value) <= parseFloat(InvoiceAmount)) {
            event.target.value = value;
        } else {
            event.target.value = '';
            setNotif({
                notifMsg: `Please enter a amount less than or equal to ${InvoiceAmount}.`,
                open: true,
                severity: "danger",
            });
        }
        const amount = event.target.value ? (parseFloat(event.target.value)).toFixed(2) : '';
        setCNAmount(amount);
    };

    const handleStudentAmountChange = (event, studentId, studentRemaining) => {
        const { value } = event.target;
        const targetValue = parseFloat(value);
        const isValidNumber = /^\d+(\.\d+)?$/.test(value);  // Check if the value is a valid number
    
        let amount = 0;
    
        // Validate input
        if (isValidNumber && targetValue > 0 && targetValue <= parseFloat(studentRemaining)) {
            amount = targetValue.toFixed(2);  // Use targetValue directly after validation
            event.target.value = value;  // Update input value
        } else {
            event.target.value = '';
            const message = targetValue <= 0 
                ? "Please enter an amount greater than 0."
                : targetValue > parseFloat(studentRemaining)
                    ? `Please enter an amount less than or equal to ${studentRemaining}.`
                    : "Reinput the value.";
                    
            setNotif({
                notifMsg: message,
                open: true,
                severity: "danger",
            });
        }
    
        // Update selectedUserAmount state
        setSelectedUserAmount(prevAmounts => {
            // If amount is 0 or input is empty, remove studentId
            const updatedAmounts = { ...prevAmounts };
            if (amount == 0 || !value) {
                delete updatedAmounts[studentId];  // Remove the entry
            } else {
                updatedAmounts[studentId] = amount;  // Update or add the amount
            }
            return updatedAmounts;  // Return the updated amounts
        });
    };

    useEffect(() => {
        // Calculate totalAmount only if selectedUserAmount is defined
        const totalAmount = selectedUserAmount 
            ? Object.values(selectedUserAmount).reduce((acc, amount) => acc + parseFloat(amount || 0), 0)
            : 0;
    
        // Format totalAmount to 2 decimal places
        const formattedTotalAmount = totalAmount.toFixed(2);
    
        // Update input value directly via the ref
        if (amountInputRef.current) {
            amountInputRef.current.value = formattedTotalAmount > 0 ? formattedTotalAmount : null; 
        }
    
        // Update the CN amount
        setCNAmount(formattedTotalAmount);
    }, [selectedUserAmount]);
    
    

    const submitCreditNotes = async (values) => {
        setIsSubmitting(true);
        // console.log("THIS IS SUBMIT VALUEs:", values)
        if (values.type === "" || values.type === null){
            setNotif({
                notifMsg: "Please select type.",
                open: true,
                severity: "danger",
            });
            setIsSubmitting(false);
            return false;
        }

        if (values.reason === "" || values.reason === null){
            setNotif({
                notifMsg: "Please select reason.",
                open: true,
                severity: "danger",
            });
            setIsSubmitting(false);
            return false;
        }
        // if (!cnAmount){ // && (values.reason == 'offset' || values.reason == 'refund' || values.reason == 'cancellation' || values.type != 'cancel-course')
        if (cnAmount === null || cnAmount <= 0){
            // scrollToTop();
            setNotif({
                notifMsg: "Please enter amount greater than 0.",
                open: true,
                severity: "danger",
            });
            setIsSubmitting(false);
            return false;
        }
        // }
        if (files?.length === 0) {
            setNotif({
                notifMsg: "Please upload supporting documents.",
                open: true,
                severity: "danger",
            });
            setIsSubmitting(false);
            return false;
        }
        if (selectedUser?.length === 0 && studentType) {
            setNotif({
                notifMsg: "Please select students.",
                open: true,
                severity: "danger",
            });
            setIsSubmitting(false);
            return false;
        }

        
        try {
            const formData = new FormData();
            selectedUser?.map((d, index) => {
                formData.append(
                    "student_name[]",
                    (d.original?.student_name ?? d.original?.student?.name) + "-" + (d.original?.fin ?? d.original?.student?.fin)
                );
                formData.append("invoice_course_student[]", d.original?.id);
            });
            const formattedType = values.type
                .replace(/-/g, ' ')    // Replace all hyphens with spaces
                .replace(/^\w/, (c) => c.toUpperCase());  // Capitalize the first letter

            if (!isCancelCourse) {
                if (selectedUserAmount?.length) {
                    Object.entries(selectedUserAmount).forEach(([studentId, amount]) => {
                        formData.append(`invoice_course_student_amounts[${studentId}]`, amount);
                    });
                } else {
                    if (Object.keys(selectedUserAmount)?.length != selectedUser?.length) {
                        setNotif({
                            notifMsg: `Must input individual student's amount to be deducted on '${formattedType}' credit note type.`,
                            open: true,
                            severity: "danger",
                        });
                        setIsSubmitting(false);
                        return false;
                    }
                }
            }
            formData.append("invoice_id", invoiceId);
            formData.append("remarks", values.remarks ?? "");
            formData.append("type", values.type);
            formData.append("amount", cnAmount ?? '');
            formData.append("reason", (invoice.status_key.toUpperCase() === 'PENDING') ? '' : values.reason);
            formData.append("admin_fee", values.admin_fee);
            formData.append("test_fee", values.test_fee);
            formData.append("bca_fee", values.bca_fee);
            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });

            if (isCancelCourse) {
                const statusKeyToFilter = ['WITHDRAW', 'CANCELLED'];
                const selectableStudentCount = invoiceStudent.filter(({ status_key }) => !statusKeyToFilter.includes(status_key))?.length;
            
                if (selectableStudentCount !== formData.getAll("invoice_course_student[]")?.length) {
                    setNotif({
                        notifMsg: `Must select all student(s) on '${formattedType}' credit note type. Please use 'Withdraw student' type if you want to cancel/withdraw specific student(s).`,
                        open: true,
                        severity: "danger",
                    });
                    setIsSubmitting(false);
                    return false;
                }
            }

            await api.post(`tms/create-credit-notes`, formData);
            
            navigate(`/tms/invoices/view/${invoiceId}`, {
                state: {
                    open: true,
                    notifMsg: `Successfully created credit notes`,
                    severity: "success",
                },
            });
            setLoading(true);
        } catch (error) {
            setNotif({
                notifMsg: error.response.data.message ?? "Something went wrong in the server",
                open: true,
                severity: 'danger',
            })
            setIsSubmitting(false)
        }
        setLoading(false);
        setIsSubmitting(false);
    };

    return (
        <>
            <Row>
                <Col md={6}>
                    <b>Number:</b> {invoice.number}
                    <br />
                    <b>Customer:</b> {invoice?.company?.name ?? '-'} <br />
                    <b>Status:</b> {invoice?.status ?? '-'} <br />
                    <b>Total amount:</b> ${invoice?.total_amount ?? 0.00}
                    <br />
                    <b>Remaining amount:</b> ${invoice?.remaining_amount ?? 0.00}
                    <br />
                    <b>Paid amount</b> ${(parseFloat(invoice?.total_paid_amount) ?? 0.00).toFixed(2)}
                </Col>
                <Col md={6}>
                    <Button
                        variant="info"
                        onClick={() =>
                            navigate(`/tms/invoices/`, {
                                state: {
                                    page: state?.page,
                                },
                            })
                        }
                        className=" float-end"
                    >
                        <ArrowLeft size={15} />
                    </Button>
                </Col>
            </Row>
            <hr />
            {invoice.status.toLowerCase() !== 'cancelled' && (
            <Row>
                <Col md={6}>
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                await submitCreditNotes(values);
                            } catch (error) {
                                console.error( error);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            handleFormChangeDetails,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    {/* {JSON.stringify(values)} */}
                                    {/* Just commenting beccause might revert this back in the future. */}
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Select
                                                name="type"
                                                className="form-control"
                                                isInvalid={Boolean(
                                                    errors &&
                                                        touched &&
                                                        errors.type &&
                                                        touched.type
                                                )}
                                                onBlur={handleBlur}
                                                onChange={(data) => {
                                                    handleChange(data);
                                                    setStudentType((data.target.value != '') || false);
                                                    setIsCancelCourse(data.target.value == 'cancel-course' || data.target.value == 'cancel-invoice' || false);
                                                }}
                                                value={values.type || ""}
                                            >
                                                <option selected="selected" value=''>
                                                    Select credit note type
                                                </option>
                                                <option value="cancel-course">
                                                    Cancel course
                                                </option>
                                                <option value="withdraw-student">
                                                    Withdraw student
                                                </option>
                                                <option value="discount">
                                                    Discount
                                                </option>
                                                <option value="overcharge">
                                                    Overcharge
                                                </option>
                                                <option value="cancel-invoice">
                                                    Cancel invoice
                                                </option>
                                            </Form.Select>
                                            {errors && errors?.type && (
                                                <div className="text-danger">
                                                    {errors?.type}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    { (invoice?.status_key?.toUpperCase() !== 'PENDING') && (
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Reason</Form.Label>
                                                <Form.Select
                                                    name="reason"
                                                    id="reason"
                                                    className="form-control"
                                                    onBlur={handleBlur}
                                                    onChange={(data) => {
                                                        handleChange(data);
                                                        // setStudentType(data.target.value != 'cancel-course' || false);
                                                    }}
                                                    value={values.reason || ""}
                                                >   
                                                    <option value="">
                                                        Select credit note reason
                                                    </option>                                        
                                                    <option value="refund">
                                                        Refund
                                                    </option> 
                                                    <option value="offset">
                                                        Offset
                                                    </option>
                                                    {/* User wanted to be able to select no action even if cancel course */}
                                                    {/* {(!isCancelCourse) && ( */}
                                                        <option value="no-action">
                                                            No Action
                                                        </option>
                                                    {/* )} */}
                                                                                
                                                    {/* {formConfig.reason.options.map((option, index) => (
                                                        <option key={index} value={option.value}>
                                                            {option.display}
                                                        </option>
                                                    ))} */}
                                                    </Form.Select>
                                                {errors && errors?.reason && (
                                                    <div className="text-danger">
                                                        {errors?.reason}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    ) }
                                    {selectedUser?.map((d) => d.original.student_id).length > 0 && (
                                        <Col md={12} className="mb-3">
                                            <Row>
                                                <Col md={!isCancelCourse ? 6 : 12}>
                                                <h6>Selected student</h6>
                                                </Col>
                                                {!isCancelCourse && (
                                                    <Col md={6}>
                                                        <h6>
                                                        Student's amount to be deducted
                                                        </h6>
                                                    </Col>
                                                )}
                                            </Row>
                                            {selectedUser?.map((d, index) => {

                                                return (
                                                    <div className="row" key={index}>
                                                        <Col md={!isCancelCourse ? 6 : 12}>
                                                        {index + 1}
                                                        {". "}
                                                        {
                                                            d.original?.student
                                                                ?.name
                                                        }
                                                        </Col>
                                                        {!isCancelCourse && (
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Control
                                                                    type="number"
                                                                    id={'amount_' + d.original?.id}
                                                                    name={'amount_' + d.original?.id}
                                                                    onChange={(event) => handleStudentAmountChange(event, d?.original?.id, d?.original?.remaining_price)}
                                                                    // placeholder={paidAmount}
                                                                    placeholder={d.original?.remaining_price ?? 0}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    )}
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Amount
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                onChange={(event) => handleAmountChange(event)}
                                                ref={amountInputRef}
                                                // placeholder={paidAmount}
                                                placeholder={isCancelCourse ? InvoiceAmount : 0}
                                                readOnly={!isCancelCourse}
                                            />
                                            {errors && errors?.amount && (
                                                <div className="text-danger">
                                                    {errors?.amount}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <h6> Deduction fee</h6>
                                                <Form.Label>
                                                    Test Fee
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="test_fee"
                                                    onBlur={handleBlur}
                                                    onChange={
                                                        handleChange
                                                    }
                                                    value={
                                                        values.test_fee
                                                    }
                                                />
                                                <Form.Label>
                                                    BCA Fee
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="bca_fee"
                                                    onBlur={handleBlur}
                                                    onChange={
                                                        handleChange
                                                    }
                                                    value={
                                                        values.bca_fee
                                                    }
                                                />
                                                <Form.Label>
                                                    Admin fee
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="admin_fee"
                                                    onBlur={handleBlur}
                                                    onChange={
                                                        handleChange
                                                    }
                                                    value={
                                                        values.admin_fee
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                        {/* </>
                                    )} */}
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Remarks</Form.Label>
                                            <AutoGrowTextarea
                                                fieldValue={values.remarks}
                                                name="remarks"
                                                onBlur={handleBlur}
                                                handleFormChangeDetails={
                                                    handleChange
                                                }
                                            />
                                            {errors && errors?.remarks && (
                                                <div>
                                                    Refund remarks is a required
                                                    field
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <FileUpload
                                                className="fileupload-bg"
                                                files={files}
                                                setFiles={setFiles}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        {!isSubmitting && (
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="me-2"
                                                size="lg"
                                            >
                                                Save
                                            </Button>
                                        )}
                                        {isSubmitting && (
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
                {(studentType) && (
                    <Col md={6}>
                        <h5>Registered student list</h5>
                        {/* {JSON.stringify(invoiceStudent)} */}

                        {/* Old student list */}
                        {invoiceStudent?.length === 0 && (
                            <Alert className="my-3" variant="success">
                                <div className="alert-message">No students</div>
                            </Alert>
                        )}
                        {invoiceStudent?.length > 0  && (
                            <StudentList
                                setSelectedUser={setSelectedUser}
                                data={invoiceStudent}
                                columns={TableColumns}
                                invoice={invoice}
                            />
                        )}
                    </Col>
                )} 
            </Row>
            )}
            {invoice.status.toLowerCase() === 'cancelled' && (
                <div>
                    <Alert className="my-3" variant="danger">
                        <div className="alert-message">
                            Invoice cancelled{" "}
                        </div>
                    </Alert>
                </div>
            )}
        </>
    );
};

export default FormRow;
