import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { addDefaultOption, formatCsvStudentNames, formatStudentNames } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import VerifiedStudentsTable from "../../Quotation/Forms/VerifiedStudentsTable";
import { useParams } from "react-router-dom";

const CourseInformation = ({
    control,
    index,
    configValues,
    handleCourseChange,
    errors,
    setValue,
    getValues,
    adhocInvoice,
    updateAllSubtotals,
}) => {
    const [studentArrays, setStudentArrays] = useState([]);
    const [selectedOption, setSelectedOption] = useState("studentNames");
    let { id} = useParams();
    const handleOptionChange = (option) => {
        // Clear student information when switching options
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index] = [];
            return newStudentArrays;
        });

        // Update selected option
        setSelectedOption(option);
    };

    const verifyStudents = (index) => {
        // Get the value from the student_names input box
        const studentNames = getValues(`items[${index}].student_names`);
        if (!studentNames || studentNames.trim() === '') {
            return;
        }
        // Split the string by lines and create an array of objects
        const students = formatStudentNames(studentNames); 

        // Update the studentArrays in the component state
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];

            if (newStudentArrays[index]) {
                newStudentArrays[index] = [...newStudentArrays[index], ...students];
            } else {
                // If no existing array, create a new one
                newStudentArrays[index] = students;
            }

            return newStudentArrays;
        });

        // Update the form's value for items[${index}].student

        setValue(`items[${index}].student_names`, '');
        // Do whatever you need to do with the array of objects here
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const fileContent = event.target.result;

                // Parse the file content manually or using a different library
                // Here is a simple example assuming the file is in CSV format
                const rows = fileContent.split('\n');

                const studentsFromExcel = formatCsvStudentNames(rows);

                // Append to the studentArrays in the component state
                setStudentArrays((prevStudentArrays) => {
                    const newStudentArrays = [...prevStudentArrays];
                    // Check if there is an existing array for the index
                    if (newStudentArrays[index]) {
                        newStudentArrays[index] = [...newStudentArrays[index], ...studentsFromExcel];
                    } else {
                        // If no existing array, create a new one
                        newStudentArrays[index] = studentsFromExcel;
                    }
                    return newStudentArrays;
                });


            };

            reader.readAsText(file);
        }
    };

    const handleStudentNameChange = (index, studentIndex, event) => {
        const newName = event.target.value;
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index][studentIndex].name = newName;
            return newStudentArrays;
        });

    };

    const handleStudentIdNumberChange = (index, studentIndex, event) => {
        const newIdNumber = event.target.value;
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index][studentIndex].id_number = newIdNumber;
            return newStudentArrays;
        });

    };

    const removeStudent = (index, studentIndex) => {
        const shouldRemove = window.confirm("Are you sure you want to remove this student?");

        if (shouldRemove) {
            setStudentArrays((prevStudentArrays) => {
                const newStudentArrays = [...prevStudentArrays];
                newStudentArrays[index].splice(studentIndex, 1);
                return newStudentArrays;
            });

        }
    };

    useEffect(() => {
        const currentStudents = studentArrays[index];
        const nextItemType = getValues(`items[${index + 1}].item_type`);
        const studentCount = currentStudents?.length > 0 ? currentStudents.length : 1;
    
        // Set students for the current index
        setValue(`items[${index}].students`, currentStudents);
        // Set total_student based on the presence of students
        setValue(
            `items[${index}].total_student`,
            studentCount
        );
        // If the next item type is 'discount', update its total_student
        if (nextItemType === 'discount') {
            setValue(
                `items[${index + 1}].total_student`,
                -studentCount
            );
        }
        updateAllSubtotals();
    }, [studentArrays, setValue, index]);

    const extractStudentsFromQuotationData = (course) => {

        if (!course || !course.invoice_course_students) {
            return [];
        }

        return course.invoice_course_students.map(courseStudent => {
            // Modify this part based on the actual structure of your data
            const studentData = {
                name: courseStudent?.student?.name?.trim(),
                id_number: courseStudent?.student?.fin?.trim(),
                student_id:  courseStudent?.student?.id ,
                course_student_id:   courseStudent?.id 
                // Add other properties as needed
            };

            return studentData;
        });
    };

    const extractStudentsFromQuotation = () => {
        const newQuotationStudentArrays = adhocInvoice?.invoice_courses?.map((course) => {
            return extractStudentsFromQuotationData(course);
        });

            // Update the studentArrays in the component state
        if (newQuotationStudentArrays?.length) {
            setStudentArrays((prevStudentArrays) => {
                const newStudentArrays = [...prevStudentArrays];
                newStudentArrays[index] = newQuotationStudentArrays[index]?.flat();
                return newStudentArrays;
            });
        }

       

    };
    useEffect(() => {
        extractStudentsFromQuotation();
    },[adhocInvoice])
    
    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <div className="">
                            <label
                                htmlFor={`items[${index}].course_code`}
                                className="form-label"
                            >
                                Item code
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].course_code`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container "
                                                classNamePrefix="react-select"
                                                options={addDefaultOption(
                                                    configValues?.adhoc
                                                )}
                                                isClearable={true}
                                                name={field.name}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleCourseChange(
                                                        selectedOption?.value,
                                                        index
                                                    );
                                                }}
                                                value={field.value}
                                                isDisabled={id!=='add'}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].course_code && (
                                        <div className="text-danger mt-2 text-sm">
                                            Item code is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    {id=='add' && (
                    <td colSpan={1}>
                        <div className="mb-3">
                            <label className="form-label">Choose input method:</label>
                            <div>
                                <label className="mx-2">
                                    <input
                                        type="radio"
                                        name={`inputMethod_${index}`}
                                        value="studentNames"
                                        checked={selectedOption === "studentNames"}
                                        onChange={() => handleOptionChange("studentNames")}
                                    />
                                    {""} Student names
                                </label>
                                <label className="mx-2 ">
                                    <input
                                        type="radio"
                                        name={`inputMethod_${index}`}
                                        value="excel"
                                        checked={selectedOption === "excel"}
                                        onChange={() => handleOptionChange("excel")}
                                    />
                                    {""} Excel(CSV)
                                </label>
                            </div>
                        </div>

                        {selectedOption === 'studentNames' && <div className="mb-3">
                            <label
                                htmlFor={`items[${index}].student_names`}
                                className="form-label"
                            >
                                Student name and Fin No. (separated by comma)
                            </label>
                            <Controller
                                name={`items[${index}].student_names`}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <AutoGrowTextarea
                                            name={`items[${index}].student_names`}
                                            id={field.id}
                                            fieldValue={field.value}
                                            handleBlur={field.onBlur}
                                            isInvalid={!!errors.student_names}
                                            handleFormChangeDetails={
                                                field.onChange
                                            }
                                        />
                                    </>
                                )}
                            />
                            <br/>
                            <button className="btn btn-sm btn-info" onClick={(e) => {
                                e.preventDefault(); // Prevent the default form submission
                                verifyStudents(index);
                            }}>Verify</button>
                        </div>
                        }
                        {selectedOption === 'excel' && <div className="mb-3">
                            <label htmlFor={`fileInput_${index}`} className="form-label">
                                Choose Excel File
                            </label>
                            <br/>
                            <input
                                type="file"
                                id={`fileInput_${index}`}
                                onChange={handleFileChange}
                                accept=".xlsx, .xls, .csv"
                                style={{ width: "300px" }}
                            />

                        </div>
                        }
                        {errors &&
                            errors.items &&
                            errors.items[index] &&
                            errors.items[index].student_names && (
                                <div className="text-danger text-sm">
                                    Students is required
                                </div>
                            )}
                    </td>
                    )}
                    <td colSpan={1} valign="top">
                        <div className="px-2">
                            <strong>Verified students:</strong>
                            <VerifiedStudentsTable
                                studentArray={studentArrays[index] || []}
                                onNameChange={(studentIndex, e) => handleStudentNameChange(index, studentIndex, e)}
                                onIdNumberChange={(studentIndex, e) => handleStudentIdNumberChange(index, studentIndex, e)}
                                onRemove={(studentIndex) => removeStudent(index, studentIndex)}
                                showRemove ={true}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CourseInformation;
