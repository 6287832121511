import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import api from "../../../../../service/api";
import ApprovalForm from "./ApprovalForm";
import CreditNote from "./CreditNote";
import InvoiceData from "./InvoiceData";
import { formatDateTimeString, getLocalStorageValue } from "../../../../../utils/utility";
import CreditNoteApprovalView from "./CreditNoteApprovalView";



const CreditNoteDetails = () => {
    const { id, reroute } = useParams(); // credit note id and original route
    const navigate = useNavigate();
    const [creditNotes, setCreditNote] = useState();
    const location = useLocation();
    const state = location.state;
    const setReroute = reroute ?? getLocalStorageValue('cn-back-button-redirect', '/tms/summary-credit-notes')
    const [canView, setCanView] = useState(true)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const option = urlParams.get("option");
    const page = urlParams.get("view");
    const status = urlParams.get("page");
    const approval_type = urlParams.get("approval_type");

    /** notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [loading, setLoading] = useState(false);
    /** get the invoice */
    const getCreditNote = useCallback(async (id) => {
        try {
            setLoading(true);

            const response = await api.get(`tms/credit-notes/${id}?option=${option}&approval_type=${approval_type}`);
            setCreditNote(response.data);
            checkIfApprovalFormView(approval_type, response.data)
            setLoading(false);
        } catch (error) {
            if(error.response.status === 422){
                //if payload "status" is empty
                setLoading(false)
                alert(error.response.data.message)
            }else if(error.response.status === 401){
                //if unauthorized
                navigate("/tms/401")
            }
            else{
                //if other errors
                setLoading(false)
                alert(error.response.data.message)
            }
        }
    }, []);

    const checkIfApprovalFormView = async(type, credit_note) => {
        setCanView(true)
        if(type?.toUpperCase() === 'SALES_DIRECTOR' && credit_note?.sales_director_status !== null){
            setCanView(false)
        }
        if(type?.toUpperCase() === 'FINANCE_DIRECTOR' && credit_note?.finance_director_status !== null){
            setCanView(false)
        }
    }

    useEffect(() => {
        getCreditNote(id);
    }, [id, getCreditNote]);
    
    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <React.Fragment>
                <Helmet title="Credit note details" />
                <Container fluid className="p-0">
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <>
                            <Row>
                                <Col md={6}>
                                    <h1 className="h3 mb-3">
                                        Credit Note Details <Badge bg={creditNotes?.status_badge_color}>{creditNotes?.status}</Badge>
                                    </h1>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button
                                        className="ms-2 "
                                        onClick={() =>
                                            navigate( `/tms/${setReroute}` )
                                        }
                                    >
                                        <ArrowLeft className="feather" />
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                {option?.toUpperCase() !== "APPROVAL" && (
                                    <>
                                        <Col md="6" xl="5">
                                            <InvoiceData creditNotes={creditNotes} />
                                            <Col>
                                                <Card>
                                                    <Card.Body>
                                                      
                                                    <section>
                                                            {creditNotes?.status === 'Withdraw' ? (
                                                                <table className="withdrawn-table">
                                                                    <tr>
                                                                        <th width={200}>Status:</th>
                                                                        <td>{creditNotes.status}</td>
                                                                    </tr>
                                                                    {creditNotes?.withdraw_remarks && (
                                                                        <tr>
                                                                            <th width={200}>Withdraw Remarks: </th>
                                                                            <td>
                                                                                {creditNotes?.withdraw_remarks}
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    <tr>
                                                                        <th width={200}>User: </th>
                                                                        <td>{creditNotes.withdraw_requestor_user ? `${creditNotes.withdraw_requestor_user.first_name} ${creditNotes.withdraw_requestor_user.last_name}` : ''}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <th width={200}>Date: </th>
                                                                        <td>{ formatDateTimeString(creditNotes.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ", "YYYY-MM-DD HH:MM:SS")}</td>
                                                                    </tr>
                                                                </table>
                                                            ) : (
                                                                <table className="approval-table">
                                                                    <h4>Approval Status</h4>
                                                                    <tr>
                                                                        <th width={200}>Sales Director Status: </th>
                                                                        <td>{creditNotes?.sales_director_status ? `${creditNotes?.sales_director_status.charAt(0).toUpperCase() + creditNotes?.sales_director_status.slice(1).toLowerCase()} ${creditNotes?.sales_director_approved_date ? `- ${creditNotes?.sales_director_approved_date}` : ''}` : 'Pending'}</td>
                                                                    </tr>
                                                                        <tr>
                                                                            <th width={200}>Finance Director Status: </th>
                                                                            {(creditNotes?.sales_director_status !== 'REJECTED') && (
                                                                                <td>{creditNotes?.finance_director_status ?  `${creditNotes?.finance_director_status.charAt(0).toUpperCase() + creditNotes?.finance_director_status.slice(1).toLowerCase()} ${creditNotes?.finance_director_approved_date ? `- ${creditNotes?.finance_director_approved_date}` : ''}` : 'Pending'}</td>
                                                                            )}
                                                                    </tr>
                                                                </table>
                                                            )}
                                                        </section>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Col>
                                        <Col>
                                            <CreditNote creditNotes={creditNotes} />
                                        </Col>
                                    </>
                                )}
                                {option?.toUpperCase() === "APPROVAL" &&(
                                    <>
                                        <Col md="4" xl="3">
                                            <InvoiceData creditNotes={creditNotes} />
                                        </Col>
                                        <Col md="8" xl="9">
                                            <CreditNoteApprovalView creditNotes={creditNotes} />
                                            <br />
                                            {canView && (
                                                <ApprovalForm
                                                    creditNoteId={id}
                                                    getCreditNote={getCreditNote}
                                                    setLoading={setLoading}
                                                    approvalType={approval_type}
                                                    // canView={canView}
                                                    // checkIfApprovalFormView={checkIfApprovalFormView}
                                                />
                                            )}
                                            
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </>
                    )}
                </Container>
            </React.Fragment>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default CreditNoteDetails;
