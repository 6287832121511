// setInitialValues.js

import { commonBeforeGSTAmount } from "../../../../../utils/utility";

export const setInitialValues = (
    adhocInvoice,
    id,
    configValues,
    setValue,
    setSelectedCompany,
) => {
    if (!adhocInvoice || id === "") {
        return; // No need to proceed if quotation or id is not available
    }

    // Preselect the company by setting its option
    const companyOption = {
        label: adhocInvoice?.company?.name,
        value: adhocInvoice?.company?.id,
    };
    setSelectedCompany(adhocInvoice?.company?.id);


    setValue("company_id", companyOption.value, { shouldValidate: false });
    const locationOption = configValues?.location?.find(
        (option) => option.value === adhocInvoice?.location
    );

    if (locationOption) {
        setValue("location", locationOption);
    }
    setValue("sales_user_id", {
        label: adhocInvoice?.sales_user.name,
        value: adhocInvoice?.sales_user.id,
    });

    setValue("no_gst", adhocInvoice?.no_gst);

    setValue("update_address", adhocInvoice?.update_address);
    setValue("address", adhocInvoice?.company_addr);
    const companyContactId = adhocInvoice?.company_contact_id;
    if (companyContactId) {
        setValue("company_contact_person", companyContactId);
    }

    // Initialize an empty array to store the initial items
    const initialItems = adhocInvoice?.invoice_courses.map((item) => {
        let number_of_sessions = null;
        if (item?.invoice_course_students?.length) {
           number_of_sessions = item?.invoice_course_students[0]['no_session'] ?? null;
        }

        const courseCodeOptionConfig = configValues?.adhoc?.find(
            (option) => option.value === item?.addtnl_invoice_item_code
        );

        return {
            id: item?.id,
            total_student: item?.total_student,
            price:
                item.item_type !== "discount"
                    ? commonBeforeGSTAmount(
                        parseFloat(item.price),
                        adhocInvoice.gst
                    )
                    : parseFloat(item.price),
            original_price: parseFloat(item?.price),
            item_type: item?.item_type,
            other_item: item?.addtnl_invoice_item_code,
            course_code: courseCodeOptionConfig || null,
            no_of_sessions_per_student: number_of_sessions,
            course: {
                label: item?.course?.name || "",
                value: item?.course?.id || "",
            },
            student_names: item?.additional_adhoc_student,
            discount_item: item?.discount_item_name,
        };
    });

    setValue("items", initialItems); // Use setValue to set initial values
};
