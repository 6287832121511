import * as Yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { Alert, Col, Form, Row, Button, Card } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Plus, Trash } from "react-feather";
import api from "../../../../service/api";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";

import DatePicker from "react-datepicker";
import moment from "moment";
import { UserDateFormat } from "../../../../utils/dateFormat";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { addDefaultOption } from "../../../../utils/utility";

const BatchFormRow = () => {
    const DateFormat = UserDateFormat();
    const format = "HH:mm";

    const navigate = useNavigate();
    let { id } = useParams();

    /** Get path from summary page  */
    const { state } = useLocation();
    let redirectPath = "/tms/batches/pending";
    if (state?.path) {
        redirectPath = `/tms/batches/${state?.path}`;
    }
    /** Get path from summary page  */
    const [trainerList, setTrainerList] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [initValues, setInitialValues] = useState({
        type: "",
        name: "",
        // location: "",
        test_date: "",
        status: "",
        invigilators: [],
        training_dates: [],
        test_venue: "",
        training_venue: "",
    });
    const [editBatch, setBatch] = useState();
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [configValues, setConfigValues] = useState({
        location: [],
        status: [],
    });
    const [configEnrollment, setConfigEnrollment] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
    });
    const addInvigilator = (e) => {
        let newfield = {
            name: "",
        };
        setInitialValues({
            ...initValues,
            invigilators: [...initValues.invigilators, newfield],
        });
    };

    const deleteInvigilator = (index) => {
        let data = [...initValues.invigilators];
        data.splice(index, 1);

        setInitialValues({
            ...initValues,
            invigilators: data,
        });
    };

    const addTrainingDate = (e) => {
        let updateInitialValues = { ...initValues };
        const { training_dates } = updateInitialValues;
        training_dates.push({
            date: "",
            am: {
                from: "",
                to: "",
            },
            pm: {
                from: "",
                to: "",
            },
            ev: {
                from: "",
                to: "",
            },
            // session: [],
            trainer_id: "",
            training_type: "",
        });
        setInitialValues({ ...initValues, training_dates: training_dates });
    };

    const deleteTrainingDate = (index) => {
        let updateInitialValues = { ...initValues };
        const { training_dates } = updateInitialValues;
        training_dates.splice(index, 1);
        setInitialValues({
            ...updateInitialValues,
            training_dates: training_dates,
        });
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        test_date: Yup.string().required(),
        training_dates: Yup.array().of(
            Yup.object().shape({
                date: Yup.string().required(),
            })
        ),
        // course_id: Yup.string().required(),
    });

    const handleMultiSelectItem = (options, name, index) => {
        const data = [...initValues.training_dates];
        const selectedDate = options.map((option) => {
            return option.value;
        });
        data[index][name] = selectedDate;
        setInitialValues({ ...initValues, training_dates: data });
    };

    const handleFormChangeItems = (index, event) => {
        let data = [...initValues.invigilators];
        data[index][event.target.name] = event.target.value;
        setInitialValues({
            ...initValues,
            invigilators: data,
        });
    };

    const handleSingleDateSelection = (name, value) => {
        let data = { ...initValues };
        data[name] = value;

        setInitialValues({
            ...initValues,
            ...data,
        });
    };

    const handleDateSelection = (index, name, value) => {
        if (!value) return;

        let data = [...initValues.training_dates];
        data[index][name] = value;
        setInitialValues({
            ...initValues,
            training_dates: data,
        });
    };
    const handleFormChangeDetails = (event) => {
        let data = { ...initValues };
        let p = event.target.selectionStart;
        data[event.target.name] = event.target.value;
        setInitialValues(data);
        setTimeout(() => {
            event.target.setSelectionRange(p, p)
        });
    };

    const handleFormSelectChangeItems = (event, name, index) => {
        let data = [...initValues.training_dates];
        data[index][name] = event?.value;

        setInitialValues({
            ...initValues,
            training_dates: data,
        });
    };

    const handleFormSelectSingleChangeItems = (event, name) => {
        const data = { ...initValues };
        data[name] = event?.value;
        setInitialValues(data);
    };

    const handleFormChangeTrainingDates = (index, parent, child, event) => {
        let data = [...initValues.training_dates];
        data[index][parent][child] = event;

        setInitialValues({
            ...initValues,
            training_dates: data,
        });
    };
    //
    // Functions
    //
    const create = (values) => {
        try {
            return api.post("tms/batches", values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const update = (values) => {
        try {
            return api.put(`tms/batches/${id}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data.type?.toLowerCase() === "ato";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toLowerCase() === "attc";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const { atoCourses, attcCourses } = { ...configEnrollment };

        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigEnrollment({ atoCourses, attcCourses });
    };

    useEffect(() => {
        const getBatches = async () => {
            try {
                const configEnrollments = await api.get(
                    `tms/enrolments/config`,
                    {}
                );
                const configResponse = await api.get(`tms/batches/config`, {});
                const trainerResponse = await api.get(
                    `tms/courses/trainers`,
                    {}
                );
                const courseResponse = await api.get(`tms/courses?all=1`, {});

                await setTrainerList(trainerResponse.data);
                await setConfigValues({
                    ...configValues,
                    ...configResponse.data,
                });
                await setLoading(false);
                setCourseData(courseResponse?.data);
                setConfigEnrollment({
                    ...configEnrollment,
                    ...configEnrollments.data,
                });
            } catch (error) {}
        };
        getBatches();

        if (id && id !== "add") {
            const getBatch = async () => {
                try {
                    const response = await api.get(`tms/batches/${id}`, {});
                    await setBatch(response.data);
                } catch (error) {}
            };
            getBatch();
        }
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editBatch) {
            const updateEditData = async () => {
                await setLoading(true);
                await setInitialValues({
                    type: editBatch?.type,
                    name: editBatch?.name,
                    course: editBatch?.course,
                    course_id: editBatch?.course_id,
                    trade_category: editBatch?.trade_category,
                    location: editBatch?.location,
                    test_date: editBatch?.test_date,
                    status: editBatch?.status,
                    invigilators: editBatch?.invigilators,
                    training_dates: editBatch?.training_dates?.length
                        ? editBatch.training_dates
                        : [],
                    training_venue: editBatch?.training_venue,
                    test_venue: editBatch?.test_venue,
                    // session: editBatch.session,
                });
                await setLoading(false);
            };
            updateEditData();
        }
    }, [editBatch]);

    return (
        <>
            <Card>
                <Card.Body>
                    {!loading && (
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                try {
                                    setIsSubmitting(true);
                                    id !== "add"
                                        ? await update(values)
                                        : await create(values);

                                    navigate(`${redirectPath}`, {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                id === "add"
                                                    ? "added"
                                                    : "edited"
                                            } project`,
                                            severity: "success",
                                        },
                                    });
                                } catch (error) {
                                    const message =
                                        error?.response?.data?.message;

                                    setStatus({ success: false });
                                    setErrors({ submit: message });
                                    setNotif({
                                        notifMsg: message,
                                        open: true,
                                        severity: "danger",
                                    });
                                }
                                setSubmitting(false);
                                setIsSubmitting(false);
                            }}
                        >
                            {({
                                errors,
                                handleSubmit,
                                values,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {errors.submit && (
                                        <Alert
                                            className="my-3"
                                            variant="danger"
                                        >
                                            <div className="alert-message">
                                                {errors.submit}
                                            </div>
                                        </Alert>
                                    )}

                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Type</Form.Label>
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    options={addDefaultOption(
                                                        configValues?.departments
                                                    )}
                                                    name="type"
                                                    onChange={(event) => {
                                                        handleFormSelectSingleChangeItems(
                                                            event,
                                                            "type"
                                                        );
                                                    }}
                                                    isSearchable="true"
                                                    defaultValue={
                                                        editBatch?.type && [
                                                            {
                                                                label: editBatch?.type.toUpperCase(),
                                                                value: editBatch?.type,
                                                            },
                                                        ]
                                                    }
                                                />

                                                {!!errors.type && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.type}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    isInvalid={Boolean(
                                                        errors.name
                                                    )}
                                                    onInput={
                                                        handleFormChangeDetails
                                                    }
                                                />
                                                {!!errors.name && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Name is a required field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Test date
                                                </Form.Label>

                                                <DatePicker
                                                    name="test_date"
                                                    selected={
                                                        values?.test_date !==
                                                            "" &&
                                                        new Date(
                                                            moment(
                                                                values?.test_date,
                                                                DateFormat.toUpperCase()
                                                            ).format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        )
                                                    }
                                                    className={` form-control ${
                                                        errors?.test_date &&
                                                        "is-invalid"
                                                    }`}
                                                    size="lg"
                                                    onChange={(dates) => {
                                                        handleSingleDateSelection(
                                                            "test_date",
                                                            dates
                                                        );
                                                    }}
                                                    dateFormat={DateFormat}
                                                    placeholderText={DateFormat.toUpperCase()}
                                                />

                                                {!!errors.test_date && (
                                                    <div className="invalid-feedback d-block">
                                                        Test date is a required
                                                        field
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Test venue
                                                </Form.Label>

                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    options={addDefaultOption(
                                                        configValues?.location
                                                    )}
                                                    name="test_venue"
                                                    isMulti
                                                    onChange={(event) => {
                                                        let newEvent = {value: event?.map((item => item?.value))}
                                                        handleFormSelectSingleChangeItems(
                                                            newEvent,
                                                            "test_venue"
                                                        );
                                                        setFieldValue(
                                                            "test_venue",
                                                            newEvent?.value
                                                        );
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    defaultValue={
                                                        editBatch?.test_venue?.map(arrayValue => ({
                                                            label: arrayValue,
                                                            value: arrayValue,
                                                        }))
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Training venue
                                                </Form.Label>

                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    options={addDefaultOption(
                                                        configValues?.location
                                                    )}
                                                    name="training_venue"
                                                    isMulti
                                                    onChange={(event) => {
                                                        let newEvent = {value: event?.map((item => item?.value))}
                                                        handleFormSelectSingleChangeItems(
                                                            newEvent,
                                                            "training_venue"
                                                        );
                                                        setFieldValue(
                                                            "training_venue",
                                                            newEvent?.value
                                                        );
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    defaultValue={
                                                        editBatch?.training_venue?.map(arrayValue => ({
                                                            label: arrayValue,
                                                            value: arrayValue,
                                                        }))
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>
                                                    Select course
                                                </Form.Label>
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption(
                                                        initValues?.type?.toLowerCase() ===
                                                            "ato"
                                                            ? configEnrollment?.atoCourses
                                                            : configEnrollment?.attcCourses
                                                    )}
                                                    name="course_id"
                                                    onChange={(event) => {
                                                        handleFormSelectSingleChangeItems(
                                                            event,
                                                            "course_id"
                                                        );
                                                        setFieldValue(
                                                            "course_id",
                                                            event?.value
                                                        );
                                                    }}
                                                    placeholder="Select course"
                                                    defaultValue={
                                                        editBatch?.course && [
                                                            {
                                                                value: editBatch
                                                                    ?.course
                                                                    ?.id,
                                                                label: editBatch
                                                                    ?.course
                                                                    ?.name,
                                                            },
                                                        ]
                                                    }
                                                />
                                                {!!errors.id && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Coruse is a required
                                                        field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        {initValues?.type !== "" &&
                                            initValues?.type?.toLowerCase() ===
                                                "attc" &&
                                            configEnrollment.tradeCategory && (
                                                <>
                                                    <Col md={4}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Select trade
                                                                category
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={addDefaultOption(
                                                                    configEnrollment?.tradeCategory
                                                                )}
                                                                name="trade_category"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleFormSelectSingleChangeItems(
                                                                        event,
                                                                        "trade_category"
                                                                    );
                                                                    setFieldValue(
                                                                        "trade_category",
                                                                        event?.value
                                                                    );
                                                                }}
                                                                placeholder="Select trade category"
                                                                defaultValue={
                                                                    configEnrollment?.tradeCategory &&
                                                                    configEnrollment?.tradeCategory?.find(
                                                                        (o) =>
                                                                            o?.label ===
                                                                            editBatch?.trade_category
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                            )}
                                    </Row>
                                    <h4 className="mt-2 mb-2">
                                        Training Date:
                                    </h4>

                                    {initValues.training_dates?.map(
                                        (trainingData, i) => {
                                            return (
                                                <div key={`training-${i}`}>
                                                    <hr />
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Training
                                                                    Date
                                                                </Form.Label>
                                                                <DatePicker
                                                                    name="date"
                                                                    selected={
                                                                        trainingData?.date !==
                                                                            "" &&
                                                                        new Date(
                                                                            moment(
                                                                                trainingData?.date,
                                                                                DateFormat.toUpperCase()
                                                                            ).format(
                                                                                "YYYY-MM-DD"
                                                                            )
                                                                        )
                                                                    }
                                                                    className={` form-control `}
                                                                    size="lg"
                                                                    onChange={(
                                                                        dates
                                                                    ) => {
                                                                        console.log(dates);
                                                                        handleDateSelection(
                                                                            i,
                                                                            `date`,
                                                                            dates
                                                                        );
                                                                        setFieldValue(
                                                                            `training_dates.[${i}].date`,
                                                                            values
                                                                                .training_dates[
                                                                                i
                                                                            ]
                                                                                ?.date
                                                                        );
                                                                    }}
                                                                    dateFormat={
                                                                        DateFormat
                                                                    }
                                                                    placeholderText={DateFormat.toUpperCase()}
                                                                />
                                                                {errors?.training_dates &&
                                                                    errors
                                                                        .training_dates[
                                                                        i
                                                                    ]?.date && (
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Training
                                                                            date
                                                                            is
                                                                            required
                                                                        </Form.Control.Feedback>
                                                                    )}{" "}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Training
                                                                    Time (AM){" "}
                                                                </Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].am.from`}
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.am
                                                                            ?.from &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.am
                                                                                ?.from,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "am",
                                                                            "from",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label></Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].am.to`}
                                                                    className="form-control mt-2"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.am
                                                                            ?.to &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.am
                                                                                ?.to,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "am",
                                                                            "to",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Training
                                                                    Time (PM){" "}
                                                                </Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].pm.from`}
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.pm
                                                                            ?.from &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.pm
                                                                                ?.from,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "pm",
                                                                            "from",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label></Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].pm.to`}
                                                                    className="form-control mt-2"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.pm
                                                                            ?.to &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.pm
                                                                                ?.to,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "pm",
                                                                            "to",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Training
                                                                    Time (EV){" "}
                                                                </Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].ev.from`}
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.ev
                                                                            ?.from &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.ev
                                                                                ?.from,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "ev",
                                                                            "from",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label></Form.Label>
                                                                <br />
                                                                <TimePicker
                                                                    name={`training_dates.[${i}].ev.to`}
                                                                    className="form-control mt-2"
                                                                    defaultValue={
                                                                        trainingData
                                                                            ?.ev
                                                                            ?.to &&
                                                                        moment(
                                                                            trainingData
                                                                                ?.ev
                                                                                ?.to,
                                                                            format
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        time,
                                                                        timeString
                                                                    ) => {
                                                                        handleFormChangeTrainingDates(
                                                                            i,
                                                                            "ev",
                                                                            "to",
                                                                            timeString
                                                                        );
                                                                    }}
                                                                    format={
                                                                        format
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Trainer
                                                                </Form.Label>
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select "
                                                                    options={addDefaultOption(
                                                                        trainerList?.map(
                                                                            (
                                                                                trainer,
                                                                                index
                                                                            ) => {
                                                                                return {
                                                                                    label: trainer?.full_name,
                                                                                    value: trainer?.id,
                                                                                };
                                                                            }
                                                                        )
                                                                    )}
                                                                    name="trainer_id"
                                                                    // onChange={(value) => setFieldValue("location", value?.value)}
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isClearable={
                                                                        true
                                                                    }
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        handleFormSelectChangeItems(
                                                                            option,
                                                                            "trainer_id",
                                                                            i
                                                                        )
                                                                    }
                                                                    value={trainerList
                                                                        ?.filter(
                                                                            (
                                                                                trainer,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    trainingData?.trainer_id ===
                                                                                    trainer.id
                                                                                );
                                                                            }
                                                                        )
                                                                        .map(
                                                                            (
                                                                                mapTrainer
                                                                            ) => {
                                                                                return {
                                                                                    label: mapTrainer.full_name,
                                                                                    value: mapTrainer.id,
                                                                                };
                                                                            }
                                                                        )}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Training
                                                                    type
                                                                </Form.Label>
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select "
                                                                    options={addDefaultOption(
                                                                        [
                                                                            {
                                                                                value: "theory",
                                                                                label: "THEORY",
                                                                            },
                                                                            {
                                                                                value: "optional-theory",
                                                                                label: "OPTIONAL THEORY",
                                                                            },
                                                                            {
                                                                                value: "practical",
                                                                                label: "PRACTICAL",
                                                                            },
                                                                            {
                                                                                value: "optional-practical",
                                                                                label: "OPTIONAL PRACTICAL",
                                                                            },
                                                                            {
                                                                                value: "pre-registration",
                                                                                label: "PRE-REGISTRATION",
                                                                            },
                                                                        ]
                                                                    )}
                                                                    name="training_type"
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isClearable={
                                                                        true
                                                                    }
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        handleFormSelectChangeItems(
                                                                            option,
                                                                            "training_type",
                                                                            i
                                                                        )
                                                                    }
                                                                    defaultValue={[
                                                                        {
                                                                            value: trainingData?.training_type,
                                                                            label: trainingData?.training_type?.toUpperCase(),
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Button
                                                                variant="danger"
                                                                className="mt-4"
                                                                onClick={() =>
                                                                    deleteTrainingDate(
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <Trash
                                                                    size={15}
                                                                    className="m-0"
                                                                ></Trash>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        }
                                    )}
                                    <Button
                                        variant="success"
                                        className="mb-4"
                                        type="button"
                                        onClick={addTrainingDate}
                                    >
                                        <Plus size={15} className="m-0"></Plus>
                                    </Button>
                                    <h4 className="mt-2 mb-2">Invigilators:</h4>

                                    {initValues.invigilators &&
                                        initValues.invigilators?.map(
                                            (invigilator, i) => {
                                                return (
                                                    <div key={`contact-${i}`}>
                                                        <Row className="mt-2 mb-2">
                                                            <Col
                                                                md={1}
                                                                className="text-center mt-2"
                                                            >
                                                                {i + 1}
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Invigilator name"
                                                                    value={
                                                                        invigilator?.name
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        handleFormChangeItems(
                                                                            i,
                                                                            event
                                                                        );
                                                                        setFieldValue(
                                                                            `invigilators.[${i}].name`,
                                                                            values
                                                                                .invigilators[
                                                                                i
                                                                            ]
                                                                                ?.name
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>

                                                            <Col md={1}>
                                                                <Col
                                                                    md={1}
                                                                    className="d-flex flex-row align-items-center"
                                                                >
                                                                    <Button
                                                                        variant="danger"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            deleteInvigilator(
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        <Trash
                                                                            size={
                                                                                15
                                                                            }
                                                                            className="m-0"
                                                                        ></Trash>
                                                                    </Button>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            }
                                        )}

                                    <Button
                                        variant="success"
                                        className="mb-4"
                                        type="button"
                                        onClick={addInvigilator}
                                    >
                                        <Plus size={15} className="m-0"></Plus>
                                    </Button>
                                    <hr />
                                    <div className="d-flex flex-row align-items-center p-2">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            type="submit"
                                            className="col-md-3 "
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </Button>

                                        <Button
                                            variant="danger"
                                            onClick={() =>
                                                navigate(redirectPath)
                                            }
                                            className="m-3"
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Card.Body>
            </Card>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
export default BatchFormRow;
