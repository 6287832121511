import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../service/api";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import ContactSection from "./ContactSection";
import { useSelector } from "react-redux";
import ItemRow from "./ItemRow";
import { ConfirmModal } from "../../../../../components/ConfirmModal";
import { schema } from "./validationSchema";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { setInitialValues } from "./setInitialValues";
import CompanyAddressSection from "./CompanyAddressSection";
const FormRow = ({ configValues, quotation, courseFee }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;

    const [modalInfo, setModalInfo] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "success",
    });
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [hasMoreCompany, setHasMoreCompany] = useState(true);
    const [salespersons, setSalespersons] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressId, setAddressId] = useState([]);
    const [customerCode, setCustomerCode] = useState();
    const [isUpdateAddressChecked, setIsUpdateAddressChecked] = useState(false);
    const [defaultContactData, setDefaultContactData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [selectedAddress, setSelectedAddress] = useState();
    // const [selectedSequence, setSelectedSequence] = useState();
    const [selectedComapanySequence, setSelectedCompanySequence] = useState();

    const handleLoadMoreCompany = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }
        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });
        const { options: newOptions, hasMore: newHasMore } = response;
        setHasMoreCompany(newHasMore);
        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const getCompanySalesPerson = async (companyId) => {
        if (!companyId) {
            return;
        }
        const response = await api.get(`tms/companies-sales/${companyId}`, {});

        const groupedOptions = [
            {
                label: "Default",
                options: [],
            },
            {
                label: "Current User",
                options: [],
            },
            {
                label: "All Salesperson",
                options: [],
            },
        ];

        ["default", "current_user", "all"].forEach((groupKey, index) => {
            if (response.data.hasOwnProperty(groupKey)) {
                groupedOptions[index].options = Object.entries(
                    response.data[groupKey]
                ).map(([key, item]) => ({
                    label: item.label,
                    value: item.value,
                }));
            }
        });

        setSalespersons(groupedOptions);
    };

    const getCompanyData = async (companyId) => {
        try {
            const response = await api.get(`tms/companies/${companyId}`);
            setAddress(response?.data?.addtnl_address);

            //get the data default if the company has one
            setValue("address", response?.data?.full_address);
            // const default_address = response?.data?.addtnl_address?.find(
            //     (data) => data?.default === true
            // );

            // if( selectedAddress === null ) { setSelectedAddress(default_address.address); }
            // if( selectedSequence === null ) { setSelectedSequence(default_address?.sequence); }

            setContacts(response?.data?.contacts);
            setAddressId(response?.data?.id);
            setCustomerCode(response?.data?.customer_code);

            let default_contact_person = response?.data?.contacts.find(
                (data) => data.id === quotation?.company_contact_id
            );
            let default_contact_person_data = [];
            default_contact_person_data.name = default_contact_person.name;
            default_contact_person_data.contact_number =
                default_contact_person.contact_number;

            setDefaultContactData(default_contact_person_data);
        } catch (error) {
            // Handle errors here
        }
    };

    const setCompanyDataEmpty = () => {
        setValue("address", "");
        setContacts([]);
        setSalespersons([]);
        setIsUpdateAddressChecked(false);
        // setSelectedAddress(null)
        // setSelectedSequence(null)
        // setAddress([])
    };

    const onSubmit = async (data) => {
        try {
            setModalInfo({
                values: data,
                notifMsg: "Are you sure you want to create this quotation?",
                open: true,
                severity: "success",
            });
        } catch (error) {
            // Handle errors here
        }
    };

    const createQuotation = async (values) => {
        setIsSubmitting(true);
        // return;
        try {
            //assign the selected address to the proper parameter
            // values.company_address = selectedAddress
            const response = await api.post("tms/sales-quotations", values);
            if (response.status === "ok") {
                navigate("/tms/quotations", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
    };

    /** Update Quotation */
    const updateQuotation = async (values) => {
        setIsSubmitting(true);
        try {
            const response = await api.put(
                `tms/sales-quotations/${id}`,
                values
            );
            if (response.status === "ok") {
                navigate("/tms/quotations", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
            // setError(error?.response?.data?.message);
        }
        setIsSubmitting(false);
    };

    /** functin to handle a newly selected company */
    const handleCustomerChange = (value) => {
        // Remove the currently selected company address
        setValue("address_sequence", null);
        setValue('company_address', null);
        setValue('address', null);
        setValue('default_addres', null);
        // setSelectedAddress(null)
        // setSelectedSequence(null)
        // Remove the currently selected contact person
        setValue("company_contact_person", null);
        setValue("default_contact_person", null);
        setValue("default_contact_number", null);
        // Remove the currently selected sales person
        setValue("sales_user_id", null);

        setValue(
            "company_id",
            value?.value,
            { shouldValidate: true }
        ); // Trigger validation manually
        // getCompanySalesPerson(
        //     value?.value
        // );
        setSelectedCompany(
            value?.value
        );
        if (!value?.value) {
            setCompanyDataEmpty();
        }
        setSelectedCompanySequence(null)
    }

    useEffect(() => {
        if (selectedCompany) {
            getCompanyData(selectedCompany);
            getCompanySalesPerson(selectedCompany);
        }
    }, [selectedCompany]);

    useEffect(() => {
        setInitialValues(
            quotation,
            id,
            configValues,
            setValue,
            setSelectedCompany
        );
    }, [quotation, configValues, id]);

    useEffect(() => {
        if (!address?.length) return;
    
        // selecting the saved addresss or the default address if on create.
        address.forEach((data) => {
            if ((!quotation && data?.default) || 
                (quotation && data?.address?.toUpperCase() === quotation?.company_addr)) {
                setValue("address", data?.address);
                setValue("default_address", data?.address);
                setSelectedCompanySequence(data?.sequence);
            }
        });
    }, [address, quotation]);
    
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Customer
                            </label>
                            {id !== "add" && (
                                <>
                                    <br />
                                    {quotation?.company.name}
                                </>
                            )}
                            {id === "add" && (
                                <Controller
                                    name="company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <AsyncPaginate
                                                isClearable
                                                name={field.name}
                                                loadOptions={
                                                    handleLoadMoreCompany
                                                }
                                                hasMore={hasMoreCompany}
                                                additional={{
                                                    page: 1,
                                                }}
                                                onChange={(value) => {
                                                    handleCustomerChange(value);
                                                }}
                                                debounceTimeout={1000}
                                            />

                                            {!!errors.company_id && (
                                                <div className="text-danger text-sm">
                                                    Customer is required
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            )}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Location
                            </label>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.location
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.location
                                            )}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "location",
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                        />
                                        {!!errors?.location && (
                                            <div className="invalid-feedback">
                                                Location is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Sales person
                            </label>
                            <Controller
                                name="sales_user_id"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.sales_user_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={salespersons}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    field.name,
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                        />

                                        {!!errors?.sales_user_id && (
                                            <div className="invalid-feedback">
                                                Sales person is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {selectedCompany && (
                            <CompanyAddressSection
                                address={address}
                                addressId={addressId}
                                permission={permission}
                                control={control}
                                setValue={setValue}
                                configValues={configValues}
                                selectedCompany={selectedCompany}
                                getCompanyData={getCompanyData}
                                quotation={quotation}
                                errors={errors}
                                getValues={getValues}
                                // selectedAddress={selectedAddress}
                                // setSelectedAddress={setSelectedAddress}
                                // selectedSequence={selectedSequence}
                                // setSelectedSequence={setSelectedSequence}
                                selectedComapanySequence={selectedComapanySequence}
                                setSelectedCompanySequence={setSelectedCompanySequence}
                            />
                        )}
                    </Col>
                    <Col md={8}>
                        {selectedCompany && (
                            <ContactSection
                                contacts={contacts}
                                permission={permission}
                                control={control}
                                setValue={setValue}
                                configValues={configValues}
                                selectedCompany={selectedCompany}
                                getCompanyData={getCompanyData}
                                quotation={quotation}
                                defaultContactData={defaultContactData}
                                errors={errors}
                                getValues={getValues}
                            />
                        )}
                    </Col>
                </Row>
                <ItemRow
                    items={items}
                    setItems={setItems}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    configValues={configValues}
                    courseFee={courseFee}
                    errors={errors}
                    quotation={quotation}
                />
                <hr />

                {id === "add" && !isSubmitting && (<>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                if (!customerCode || customerCode == ''){
                                    setNotif({
                                        notifMsg: "Cannot save quotation. Seletected customer does not have customer code.",
                                        open: true,
                                        severity: "danger",
                                    });
                                    setIsSubmitting(false);
                                    return;
                                }else{
                                    setModalInfo({
                                        values: data,
                                        notifMsg:
                                            "Are you sure you want to create this quotation?",
                                        open: true,
                                        severity: "success",
                                        onConfirm: () => createQuotation(data),
                                    });
                                }
                            })();
                        }}
                        disabled={isSubmitting}
                    >
                        Create
                    </button>

                    <button
                        type="button"
                        className="btn btn-warning ms-2"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                if (!customerCode || customerCode == ''){
                                    setNotif({
                                        notifMsg: "Cannot save quotation. Seletected customer does not have customer code.",
                                        open: true,
                                        severity: "danger",
                                    });
                                    setIsSubmitting(false);
                                    return;
                                }else{
                                    data.is_draft = true;
                                    setModalInfo({
                                        values: data,
                                        notifMsg:
                                            "Save this quotation as draft?",
                                        open: true,
                                        severity: "success",
                                        onConfirm: () => createQuotation(data),
                                    });
                                }
                            })();
                        }}
                        disabled={isSubmitting}
                    >
                        Save as draft
                    </button>
                    </>)}

                {id !== "add" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setModalInfo({
                                values: getValues(), // Pass the current form values
                                notifMsg:
                                    "Are you sure you want to update this quotation?",
                                open: true,
                                severity: "success",
                                onConfirm: () => updateQuotation(getValues()), // Call the update function when confirmed
                            });
                        }}
                        disabled={isSubmitting}
                    >
                        Update
                    </button>
                )}
                {!isSubmitting && (
                <button
                    type="button"
                    size="lg"
                    onClick={() => navigate("/tms/quotations")}
                    className="btn btn-danger ms-2"
                >
                    Cancel
                </button>
                )}

                {isSubmitting && (
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                )}
            </form>
            {/* <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={createSaving}
            /> */}
            <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={modalInfo.onConfirm} // Call the update function when confirmed
            />

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default FormRow;
