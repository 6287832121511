import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { Card, Container, Alert, Row, Col, Button } from "react-bootstrap";

import { Lists } from "./List";

import { dataList } from "./dataList.js";
import { dataSummary } from "./dataSummary.js";

import Search from "./Search";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import download from "downloadjs";

const CreditNotes = ({ status, title, link }) => {
    const navigate = useNavigate();
    const [creditNotes, setCreditNotes] = useState([]);
    const [tableColumns, setTableColumns] = useState(tableColumns);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false)
    const [error, setError] = useState();
    const [companies, setCompanies] = useState([]);
    const [requestors, setRequestors] = useState([])
    const location = useLocation();
    const state = location.state;
    const reroute = link ?? location?.pathname ?? null;

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [searchValue, setSearchValue] = useState({
        number: "",
        company: "",
        refund: "",
        status: status,
		start_date: "",
        end_date: "",
        sales_director_approval: "",
        finance_director_approval: "",
        requestor: "",
    });
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getCreditNotes(searchParam);
    };

    const getCreditNotes = useCallback(
        async (search) => {
            try {
                setSearching(true)
                setLoading(true);
                const response = await api.get(
                    `tms/credit-notes?status=${status}`,
                    search
                );
                setCreditNotes(response);
                setSearching(false)
                setError();
                setLoading(false);
            } catch (error) {
                if(error.response.status === 401)
                    navigate('/tms/401')
                else{
                    setLoading(false);
                    setError(error.message);
                    setCreditNotes([]);
                }
            }
        },
        [status]
    );

    const getRequestors = useCallback(
        async() => {
            try{
                setLoading(true)
                const response = await api.get('tms/credit-notes-requestors');
                setRequestors(response?.data)
                setLoading(false)
            }catch(error){
                if(error.response.status === 401)
                    navigate('/tms/401')
                else{
                    setLoading(false);
                    setError(error.response?.data?.message);
                    setCreditNotes([]);
                }
            }
        }, []
    )

    const getCompany = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get("tms/companies?all=1");
            setCompanyData(response.data);
            setError();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }, []);

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });

        setCompanies(formatData);
    };
	
	const generateCreditNotes = async () => {
        try {
            setLoading(true);
            const excelFilename = `credit-notes-${new Date()
                .toISOString()
                .slice(0, 10)}`;

            const response = await api.getpdf(
                "tms/export-excel-credit-notes",
                searchValue
            );

            if (response.size !== 0) {
                download(response, excelFilename, "application/pdf");
            } else {
                setError("No data to be exported");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    //
    // useEffects
    //
    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getCreditNotes({ page: pageNo });
        
        setTableColumns(dataList)
        if(status === 'summary'){
            setTableColumns(dataSummary)
        }
        // getCompany();
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getRequestors()
    }, [])

    const isFinanceDirectorOrApproved = status === "finance-director" || status === 'approved';
    const isSummaryTitle = title?.toUpperCase() === "SUMMARY";

    const departmentPrefix = isFinanceDirectorOrApproved ? "Finance > " : (isSummaryTitle ? "Reports > " : "Sales > ");
    const noteType = (status === 'approved') ? "Approved credit notes" : "Credit notes " + (isSummaryTitle ? title : "request");

    const helmetTitle = departmentPrefix + noteType;


    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Credit notes {title}</h1>
                {error && (
                    <Card.Header>
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    </Card.Header>
                )}
                <Card>
                    <Card.Body className="mt-0">
                        <Row className="mb-4">
                            <Col >
                                <Search
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    companies={companies}
                                    search={search}
                                    status={status}
                                    generateCreditNotes={generateCreditNotes}
                                    requestors={requestors}
                                    searching={searching}
                                />
                            </Col>
                        </Row>

                        {loading && <div>Loading...</div>}
                        {!loading && creditNotes?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={creditNotes}
                                creditNoteApi={search}
                                status={status}
                                linkReroute={reroute}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default CreditNotes;
