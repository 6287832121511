import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    beforeGSTAmount,
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    roundToTwoDecimalDigits,
    totalGST,
} from "../../../../../utils/utility";

import { Button, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import CourseInformation from "./CourseInformation";
import OtherItem from "./OtherItem";
import { Plus, Trash } from "react-feather";
import { useParams } from "react-router-dom";

const ItemRow = ({
    configValues,
    control,
    setValue,
    getValues,
    courseFee,
    errors,
    adhocInvoice,
    adhocType,
    reset,
}) => {
    const { fields, append, insert, remove, update } = useFieldArray({
        control,
        name: "items",
    });
    let { id} = useParams();
    const [subtotals, setSubtotals] = useState([]);
    const [originalSubtotals, setOriginalSubtotals] = useState([]);

    const [cumulativeSubtotal, setCumulativeSubtotal] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [noGST, setNoGST] = useState(false);
    const gstRate = parseFloat(configValues.gst);
    const [sessionOptional, setSessionOptional] = useState([]);
    const [indexWithDiscount, setIndexWithDiscount] = useState([]);
    const [isPercentDiscount, setIsPercentDiscount] = useState([]);

    // Function to calculate the cumulative subtotal
    const calculateCumulativeSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateSubtotal(index);
            newCumulativeSubtotal += subtotal;
        });

        return newCumulativeSubtotal;
    };

    // Function to calculate the cumulative subtotal
    const calculateCumulativeDiscountSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateSubtotal(index);

            // Only add subtotals for "course" items
            if (field.item_type !== "course") {
                newCumulativeSubtotal += subtotal;
            }
        });

        return newCumulativeSubtotal;
    };

    const calculateDiscountBasePercentage = (index, percentage) => {
        var coursePrice = parseFloat(getValues(`items[${index-1}].original_price`));
        var discount = coursePrice * (percentage / 100);
        setValue(`items[${index}].original_price`, discount?.toFixed(6));
        setValue(`items[${index}].price`, discount?.toFixed(6));
        updateAllSubtotals();
        updateTotals();
    };

    const calculateOriginalSubtotal = (index) => {
        const price = parseFloat(getValues(`items[${index}].original_price`));
        let totalStudents
        // 02132024 - changed so that no of sessions * total_student
        // Check if session is optional
        if(sessionOptional[index] === undefined){
            totalStudents = getValues(`items[${index}].total_student`) ?? 1
        }else{
            totalStudents = sessionOptional[index] ?
            parseFloat(getValues(`items[${index}].total_student`))  
            : parseFloat(getValues(`items[${index}].total_student`))
                * parseFloat(getValues(`items[${index}].no_of_sessions_per_student`));
        }   
        
        // console.log(sessionOptional[index], "Total stud", totalStudents, getValues(`items[${index}].total_student`), getValues(`items[${index}].no_of_sessions_per_student`));

        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            console.log("subtotal", subtotal)
            if (isNaN(subtotal)){
                return 0;
            }
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    // Function to add discount row
    const addDiscountRow = (event) => {
        const index = parseInt(event.target.getAttribute('data-index')) + 1;
        // Use insert instead of append. The index is the index after the course_item of which the clicked button belongs to.
        insert(parseInt(index), {total_student: -1, price: 0, item_type: "discount"});
        // append({ total_student: -1, price: 0, item_type: "discount" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    const calculateCumulativeOriginalSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateOriginalSubtotal(index);
            // if (field.item_type !== "discount") {
            //     newCumulativeSubtotal += subtotal;
            // }
            newCumulativeSubtotal += subtotal;
        });

        return newCumulativeSubtotal;
    };


    const updateTotals = async () => {
        const newCumulativeSubtotal = calculateCumulativeSubtotal();
        let newCumulativeOriginalSubtotal =
            calculateCumulativeOriginalSubtotal();
        // const newTotalDiscount = calculateCumulativeDiscountSubtotal();
        const totalAmount = newCumulativeOriginalSubtotal;
        let newGstAmount = totalAmount - newCumulativeSubtotal;

        if (noGST || adhocType === "no_gst") {
            newGstAmount = 0;
        }

        // Calculate the total amount as the difference between
        // the sum of all original amounts and the sum of all discount original amounts

        setCumulativeSubtotal(newCumulativeSubtotal);
        setGstAmount(newGstAmount);
        setTotalAmount(totalAmount);
        // setTotalDiscount(newTotalDiscount);
        setValue("subtotals", newCumulativeSubtotal);
        setValue("gst_amount", roundToTwoDecimalDigits(newGstAmount));
        setValue("total_amount", totalAmount);
    };

    const setTotalandGst = (gst_amount) => {
        const newTotal = cumulativeSubtotal + gst_amount;
        setTotalAmount(newTotal);
        setValue("gst_amount", roundToTwoDecimalDigits(gst_amount));
        setValue("total_amount", newTotal);
    };

    const addItem = () => {
        append({ total_student: 1, price: 0, item_type: "course" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    const addOthersRow = () => {
        append({ total_student: 1, price: 0, item_type: "others" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    const updatePriceAndTotals = (index, fee, gstRate) => {
        setValue(`items[${index}].original_price`, fee);
        if (adhocType === "with_gst") {
            setValue(
                `items[${index}].price`,
                commonBeforeGSTAmount(fee, gstRate)
            );
        } else {
            setValue(`items[${index}].price`, fee);
        }

        updateSubtotal(index);
        updateTotals();
    };

    const handleCourseChange = async (courseCodeId, index) => {
        setValue(`items[${index}].price`, courseFee[courseCodeId]?.fee);

        setSessionOptional((prevSessionOptonal) => {
            const newSessionOptional = [...prevSessionOptonal];

            if (newSessionOptional[index]) {
                newSessionOptional[index] = courseFee[courseCodeId]?.session_optional;
            } else {
                // If no existing array, create a new one
                newSessionOptional[index] = courseFee[courseCodeId]?.session_optional;
            }

            return newSessionOptional;
        });

        if (courseFee[courseCodeId]?.session_optional) {
            setValue(`items[${index}].no_of_sessions_per_student`, null);
        } else {
            setValue(`items[${index}].no_of_sessions_per_student`, 1);
        }

        // Update price and totals
        updatePriceAndTotals(index, courseFee[courseCodeId]?.fee, gstRate);
        updateDiscountStudentNumber();
    };

    // Calculate subtotal for a specific item
    const calculateSubtotal = (index) => {
        console.log("calculating ")
        const field = fields[index];
        const isOther = field?.item_type === "others";
        const isDiscount = field?.item_type === "discount";

        let price = "0";
        if (adhocType === "no_gst") {
            price = parseFloat(getValues(`items[${index}].original_price`));
        } else {
            price = parseFloat(
                isOther || isDiscount
                    ? commonBeforeGSTAmount(
                        getValues(`items[${index}].price`),
                        gstRate
                    )
                    : getValues(`items[${index}].price`)
            );
        }

        // 02132024 - changed so that no of sessions * total_student
        // Check if session is optional
        let totalStudents
        if(sessionOptional[index] === undefined){
            totalStudents = getValues(`items[${index}].total_student`) ?? 1
        }else{
            totalStudents = sessionOptional[index] ?
            parseFloat(getValues(`items[${index}].total_student`))  
            : parseFloat(getValues(`items[${index}].total_student`))
                * parseFloat(getValues(`items[${index}].no_of_sessions_per_student`));

        }
        
        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            if (isNaN(subtotal)){
                return 0;
            }
                
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    const getOriginalPrice = (index) => {
        let priceValue = getValues(`items[${index}].original_price`);

        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return formatNumberWithCommas(priceValue);
    };

    const discountBeforeGSTAmount = (index) => {
        let priceValue = getValues(`items[${index}].price`);
        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return commonBeforeGSTAmount(priceValue, gstRate);
    };

    const updateSubtotal = (index) => {
        const newSubtotals = [...subtotals];
        const newOriginalSubtotals = [...originalSubtotals];
        newSubtotals[index] = calculateSubtotal(index);
        newOriginalSubtotals[index] = calculateOriginalSubtotal(index);
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);

        // Update all totals after changing the subtotal
        updateTotals();
    };
    // Function to update all subtotals when "Apply no GST" is toggled
    const updateAllSubtotals = () => {
        const newSubtotals = fields.map((_, index) => calculateSubtotal(index));
        const newOriginalSubtotals = fields.map((_, index) =>
            calculateOriginalSubtotal(index)
        );
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);
        // Update all totals after toggling "Apply no GST"
        updateTotals();
    };

    const removeItem = (index) => {
        // Remove the item from the list
        remove(index);

        // When the course item with discount is removed.
        if (indexWithDiscount.includes(index)){
            //removing discount row of the deleted course item.
            remove(index);
        }

        // Update the subtotal for the removed item
        const newSubtotals = [...subtotals];
        newSubtotals.splice(index, 1);
        setSubtotals(newSubtotals);
        setOriginalSubtotals([...originalSubtotals]);
        // Update all totals after removing the item
        updateTotals();
    };

    const updateDiscountStudentNumber = () => {
        // Updating the total student of the discounts
        fields.forEach((field, index) => {
            if (field.item_type === 'discount') {
                // Getting the value of the discount's parent course_item total_student.
                // const totalStudents = getValues(`items[${index-1}].total_student`);
                const totalStudents = sessionOptional[index] ?
                    parseFloat(getValues(`items[${index-1}].total_student`))  
                    : parseFloat(getValues(`items[${index-1}].total_student`))
                        * parseFloat(getValues(`items[${index-1}].no_of_sessions_per_student`) ?? 1);
                // Setting the discount's total_student value.
                setValue(`items[${index}].total_student`, -totalStudents); // Value of the discount should always be negative.
                // updateSubtotal(index);
            }
        });
        updateAllSubtotals();
        updateTotals();
    }
    
    useEffect(() => {
        const fetchData = async () => {
            // Update all subtotals when "Apply no GST" is toggled
            await updateAllSubtotals();
            // Update GST-related values
            if (adhocInvoice && adhocInvoice.gst_amount !== undefined && !noGST) {
                setValue("gst_amount", adhocInvoice.gst_amount);
                setGstAmount(adhocInvoice?.gst_amount);
            }
        };

        fetchData(); // Call the async function
    }, [noGST, fields]);

    useEffect(() => {
        if (adhocInvoice?.invoice_courses && courseFee) {
            for (const [index, field] of adhocInvoice?.invoice_courses?.entries()) {
                handleCourseChange(field?.addtnl_invoice_item_code, index);
            }
        }
    }, [courseFee]);

    useEffect(() => {
        // Update all subtotals when "Apply no GST" is toggled
        setTotalAmount(parseFloat(cumulativeSubtotal) + parseFloat(gstAmount));
    }, [gstAmount]);

    useEffect(() => {
        const withDiscount = fields.reduce((accumulator, value, index) => {
            if (value.item_type === 'discount') {
                // Since the discount row is always inserted after a course item, decrement index by 1.
                accumulator.push(index - 1);
            }
            return accumulator;
        }, []);
        setIndexWithDiscount(withDiscount);

        updateDiscountStudentNumber();

    }, [fields])

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th width="1%">#</th>
                        <th width="35%">Course information</th>
                        <th width="5%">Student</th>
                        <th width="10%">Price</th>
                        <th width="3%">Subtotal</th>
                        <th width="2%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.length === 0 && (
                        <tr>
                            <td colSpan={6}>No items</td>
                        </tr>
                    )}
                    {fields.map((field, index) => (
                        <tr key={field.id} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                {index + 1}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <>
                                    {field.item_type === "course" && <CourseInformation
                                        control={control}
                                        index={index}
                                        configValues={configValues}
                                        handleCourseChange={
                                            handleCourseChange
                                        }
                                        errors={errors}
                                        setValue={setValue}
                                        getValues={getValues}
                                        adhocInvoice = { adhocInvoice}
                                        updateAllSubtotals={updateAllSubtotals}
                                    />}
                                </>

                                {field.item_type === "discount" && (
                                    <Controller
                                        name={`items[${index}].discount_item`}
                                        control={control}
                                        defaultValue={
                                            "Less discount allowed" || ""
                                        }
                                        render={({ field }) => (
                                            <AutoGrowTextarea
                                                name={field.name}
                                                id={field.id}
                                                fieldValue={field.value}
                                                handleBlur={field.onBlur}
                                                isInvalid={
                                                    !!errors.discount_item
                                                }
                                                handleFormChangeDetails={
                                                    field.onChange
                                                }
                                            />
                                        )}
                                    />
                                )}
                                {/* {sessionOptional[index] && sessionOptional[index] !== undefined && !indexWithDiscount.includes(index) && field.item_type == "course" && ( */}
                                {id == 'add' && !indexWithDiscount.includes(index) && field.item_type == "course" && (
                                            <>
                                                <Button
                                                    variant="secondary"
                                                    onClick={addDiscountRow}
                                                    className="mt-1"
                                                    data-index={index}
                                                >
                                                    <Plus size={15}></Plus> Add discount
                                            </Button>
                                            </>
                                        )}
                                {/* {field.item_type === "adhoc" || field.item_type === "course" &&(
                                )} */}
                                {field.item_type === "others" && (
                                    <>
                                        <OtherItem
                                            control={control}
                                            index={index}
                                            configValues={configValues}
                                            handleCourseChange={
                                                handleCourseChange
                                            }
                                            errors={errors}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    </>
                                )}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].total_student`}
                                    defaultValue={field.item_type === 1}
                                    control={control}
                                    render={({ field: inputProps }) => (
                                        <Form.Control
                                            type="number"
                                            {...inputProps}
                                            onChange={(e) => {
                                                const newValue = parseFloat(
                                                    e.target.value
                                                );
                                                if (
                                                    field.item_type ===
                                                    "discount" &&
                                                    newValue > 0
                                                ) {
                                                    // If it's a discount, and the value is positive, set it to negative
                                                    inputProps.onChange(
                                                        -newValue
                                                    );
                                                    // console.log("index", index);
                                                } else {
                                                    inputProps.onChange(
                                                        newValue
                                                    );
                                                }
                                                updateSubtotal(index);
                                                // Update the fields when the total_student of a course_item is changed.
                                                // this will inturn call the useEffect of fields to update the values of the
                                                // total_student of the course_item's respective discounts if it has any.
                                                update();
                                            }}
                                            readOnly={id!=='add' || field.item_type === "discount"}
                                        />
                                    )}
                                />
                                {!sessionOptional[index] && sessionOptional[index] !== undefined && (
                                    <>
                                        <br/>
                                        <p><b>No of days per student</b></p>
                                        <Controller
                                            name={`items[${index}].no_of_sessions_per_student`}
                                            defaultValue={1}
                                            control={control}
                                            render={({ field: inputProps }) => (
                                                <Form.Control
                                                    type="number"
                                                    {...inputProps}
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        inputProps.onChange(newValue);
                                                        updateSubtotal(index);
                                                        updateDiscountStudentNumber();
                                                    }}
                                                    readOnly={id!=='add'}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].original_price`}
                                    control={control}
                                    defaultValue={0}
                                    render={({ field: inputProps }) => (
                                        <>
                                            <Form.Control
                                                style={{ width: "100%" }}
                                                type="hidden"
                                                step="0.01"
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                />

                                {field.item_type === "discount" && (
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        value={discountBeforeGSTAmount(index)}
                                        readOnly={true}
                                    />
                                )}

                                {field.item_type === "others" && (
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        value={adhocType === "no_gst" ? getOriginalPrice(index) : discountBeforeGSTAmount(index)}
                                        readOnly={true}
                                    />
                                )}

                                <Row className="mb-2">
                                    {field.item_type === "others" && (
                                        <Col md={6}>
                                            {adhocType === "with_gst" && <small>Enter after GST</small>}
                                            {adhocType === "no_gst" && <small>Enter Before GST</small>}
                                        </Col>
                                    )}

                                    {field.item_type === "discount" && (
                                        <Col md={6}>
                                            (<small>Enter after noGST</small>)
                                        </Col>
                                    )}

                                    <Col
                                        md={
                                            field.item_type !== "others" && field.item_type !== "discount"
                                                ? 12
                                                : 6
                                        }
                                    >
                                        <Controller
                                            name={adhocType === "no_gst" ? `items[${index}].original_price` : `items[${index}].price`}
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: inputProps }) => (
                                                <>
                                                    <Form.Control
                                                        style={{
                                                            width: field.item_type === "others" ? "100%" : "100%",
                                                        }}
                                                        type="number"
                                                        step="0.01"
                                                        readOnly={field.item_type === "others" || field.item_type === "discount" ? (id !== 'add' ? true : isPercentDiscount[index]) : true}
                                                        {...inputProps}
                                                        onChange={(e) => {
                                                            // Check if value is a number, then round to 2 decimal places
                                                            let value = parseFloat(e.target.value ?? 0);
                                                            
                                                            if (!isNaN(value)) {
                                                                // Round to 6 decimal places and convert back to string
                                                                value = parseFloat(value.toFixed(6));
                                                                inputProps.onChange({
                                                                    ...e,
                                                                    target: {
                                                                        ...e.target,
                                                                        value: value,
                                                                    },
                                                                });
                                                                setValue(`items[${index}].original_price`, value);
                                                                updateSubtotal(index);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                {field.item_type === 'discount' && id === 'add' && (
                                    <>
                                        {isPercentDiscount[index] && (
                                            <Row>
                                                <Col md={6}>
                                                    <small className="me-2 text-nowrap">Discount %</small>
                                                </Col>
                                                <Col md={6} className="d-flex align-items-end">
                                                    <Controller
                                                        name={`items[${index}].percent_discount`}
                                                        control={control}
                                                        defaultValue={0}
                                                        render={({ field: inputProps }) => (
                                                            <>
                                                                <Form.Control
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    type="number"
                                                                    step="0.01"
                                                                    {...inputProps}
                                                                    onChange={(e) => {
                                                                        // Check if value is a number, then round to 2 decimal places
                                                                        let value = parseFloat(e.target.value ?? 0);
                                                                        if (!isNaN(value)) {
                                                                            // Round to 6 decimal places and convert back to string
                                                                            value = parseFloat(value.toFixed(6));
                                                                            inputProps.onChange({
                                                                                ...e,
                                                                                target: {
                                                                                    ...e.target,
                                                                                    value: value,
                                                                                },
                                                                            });
                                                                            // console.log("Discount percent: " , e?.target?.value)
                                                                            calculateDiscountBasePercentage(index, value ?? 0);
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col md={6}>
                                                <small className="me-2 text-nowrap">Percent input</small>
                                            </Col>
                                            <Col md={6} className="d-flex align-items-end">
                                                <input
                                                    disabled={id!=='add'}
                                                    type="checkbox"
                                                    name="is_discount_input"
                                                    defaultChecked={isPercentDiscount[index]} // assuming you are accessing the correct index of the array
                                                    onChange={(e) => {
                                                        // Create a new array with updated value at the given index
                                                        const updatedDiscounts = [...isPercentDiscount];
                                                        updatedDiscounts[index] = e?.target?.checked ?? false;
                                                        setIsPercentDiscount(updatedDiscounts); // Update state with new array
                                                        
                                                        setValue(`items[${index}].price`, 0);
                                                        setValue(`items[${index}].percent_discount`, 0);
                                                        updateAllSubtotals();
                                                        updateTotals();
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {field.item_type === "course" && (
                                    <small>
                                        {adhocType === "with_gst" && <small>After GST </small>}
                                        {adhocType === "no_gst" && <small>Before GST </small>}
                                        {getOriginalPrice(index)}
                                    </small>
                                )}
                            </td>

                            <td
                                style={{ verticalAlign: "top" }}
                                className={(indexWithDiscount.includes(index)) ? " currency border-bottom-0" : 'currency'}
                            >
                                {formatNumberWithCommas(subtotals[index])}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                { id==='add' && 
                                <>
                                        <Button
                                        variant="danger"
                                        onClick={() => {
                                            removeItem(index);
                                        }}
                                        >
                                            <Trash size={10} />
                                        </Button> 
                                </>
                                }
                            </td>
                        </tr>
                    ))}
                    <tr>
                        
                        {adhocType && id==='add' && (
                            <>
                                {" "}
                                <td colSpan={2} className="border-0">
                                    <Button
                                        variant="secondary"
                                        onClick={addItem}
                                        className="mt-1 mx-1 "
                                    >
                                        <Plus size={15}></Plus> Add item
                                    </Button>
                                    {/* <Button
                                        variant="secondary"
                                        onClick={addOthersRow}
                                        className="mt-1"
                                    >
                                        <Plus size={15}></Plus> Add other Item
                                    </Button> */}
                                </td>
                            </>
                        )}

                        {fields.length > 0 && (
                            <>
                                <td
                                    colSpan={adhocType && id==='add' ? 2 : 4}
                                    className="text-end fs-4 border-0"
                                >
                                    Subtotal
                                </td>
                                <td className=" currency border-0">
                                    {formatNumberWithCommas(cumulativeSubtotal)}
                                </td>
                            </>
                        )}
                    </tr>
                    {fields.length > 0 && (
                        <>
                            {adhocType === "with_gst" && (
                                <>
                                    <tr>
                                        <td
                                            colSpan={4}
                                            className=" border-0"
                                        ></td>
                                        <td colSpan={2} className=" border-0">
                                            <Controller
                                                name="no_gst" // Name should match the field name in your form data
                                                control={control} // Use RHF control
                                                defaultValue={false} // Set the initial value of the checkbox
                                                render={({ field }) => (
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Apply no GST"
                                                        checked={field.value}
                                                        onChange={(e) => {
                                                            setNoGST(
                                                                e.target.checked
                                                            );
                                                            field.onChange(e);
                                                            // updateAllSubtotals(); // Recalculate totals when the option is toggled
                                                        }}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total tax amount{" "}
                                    {noGST || adhocType !== "with_gst" ? 0 : configValues.gst}%
                                </td>
                                <td className=" border-0">
                                    <Controller
                                        name="gst_amount" // Name should match the field name in your form data
                                        control={control} // Use RHF control
                                        render={({ field }) => (
                                            <Form.Control
                                                type="number"
                                                readOnly={noGST || adhocType !== "with_gst" ? true : false}
                                                {...field}
                                                step="0.01"
                                                onChange={(e) => {
                                                    setGstAmount(
                                                        e.target.value
                                                    );
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total amount
                                </td>
                                <td className="currency border-0">
                                    {formatNumberWithCommas(totalAmount)}

                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>

            <Controller
                name="subtotals"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
            {/* <Controller
                name="gst_amount"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            /> */}
            <Controller
                name="total_amount"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
        </div>
    );
};

export default ItemRow;
