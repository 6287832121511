import React, { useCallback, useEffect, useState } from "react";

import {
    Card,
    Row,
    Table,
    Col,
    Button,
    Form,
    Alert,
    Badge,
    Spinner,
} from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import Select from "react-select";

import { addDefaultOption } from "../../../utils/utility";
import SearchEnrolment from "../CommonComponents/SearchEnrolment";
import AllocatedStudents from "./AllocatedStudents";
import api from "../../../service/api";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const PendingStudentList = ({
    columns,
    data,
    getPendingStudentsApi,
    pendingSearch,
    setPendingSearch,
    batchLists,
    batch,
    getBatchDetail,
    setRerender,
    rerender,
    setNotif,
}) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const path = urlParams.get("status");
    const batchIdParam = urlParams.get("batchId");
    const batchName = urlParams.get("batchName");

    let redirectPath = "";
    let selectedBatchName = "";

    if (path && batchIdParam && batchName) {
        redirectPath = `/tms/batches/${path}`;
        selectedBatchName = batchName;
    }

    const [loading, setLoading] = useState(false);
    const [selectedBatchId, setSelectedBatchId] = useState(batchIdParam);
    const [defaultConfig, setDefaultConfig] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addingStudents, setAddingStudents] = useState(false)

    const searchFields = {
        fin: "",
        date: "",
        registration_type: "",
        type: "",
        trade_category: "",
        type_of_trade: "",
        application_option: "",
        course_id: "",
        company_id: "",
    };

    const resetSearch = () => {
        setPendingSearch(searchFields);
        getPendingStudentsApi(searchFields);
    };

    const addToBatch = async () => {
        try {
            setLoading(true);
            setIsSubmitting(true);
            setAddingStudents(true)
            const selectedStudents = selectedFlatRows.map((d) => d.original);
            await api.post(`tms/batches/${selectedBatchId}/batch-students`, {
                students: selectedStudents,
            });

            await getPendingStudentsApi();
            setLoading(false);
            setAddingStudents(false)
            setNotif({
                notifMsg: 'Added student/s to class',
                open: true,
                severity: "success",
            })
            getBatchDetail();
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "warning",
            })
            setIsSubmitting(false);
            setAddingStudents(false)
        }
    };

    const batchListArray = batchLists?.map((item, index) => {
        return { value: item?.id, label: `${item.name} - ${item.test_date}` };
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    const getConfig = useCallback(async () => {
        const configResponse = await api.get(`tms/enrolments/config`, {});
        setDefaultConfig(configResponse.data);
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]); //  eslint-disable-line react-hooks/exhaustive-deps

    const batchAllocatedStudentTableColumns = [
        {
            Header: "S.no",
        },
        {
            Header: "Name",
            accessor: (data) => {
                return <>{data?.name}</>;
            },
        },
        {
            Header: "FIN",
            accessor: (data) => {
                return <>{data?.fin || "-"}</>;
            },
        },
        {
            Header: "Sponsor company",
            id: "sponsor_company",
            accessor: (data) => {
                return (
                    <div>
                        {data?.sponsor_company}
                        {!data?.sponsor_company && (
                            <Badge bg="primary">SELF</Badge>
                        )}
                    </div>
                );
            },
        },
        {
            Header: "Type of trade",
            id: "data.type_of_trade",
            accessor: (data) => {
                return <>{data?.type_of_trade || "-"}</>;
            },
        },
        {
            Header: "Application option",
            id: "data.enrolment.application_option",
            accessor: (data) => {
                return <>{data?.application_option || "-"}</>;
            },
        },
    ];

    return (
        <React.Fragment>
            <Card.Header>
                <Row>
                    <Col md={12}>
                        <SearchEnrolment
                            search={getPendingStudentsApi}
                            setSearchValue={setPendingSearch}
                            searchValue={pendingSearch}
                            defaultConfig={defaultConfig}
                            resetSearch={resetSearch}
                            isPendingStudents={true}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={6}>
                        <Table bordered className="mb-4" size="sm">
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td>{batch?.name || "-"}</td>
                                </tr>
                                <tr>
                                    {/* {console.log("THIS IS BATCH: ", batch)} */}
                                    <th>Test venue:</th>
                                    <td>{batch?.test_venue?.join(", ") || "-"}</td>
                                </tr>
                                <tr>
                                    <th>Training venue:</th>
                                    <td>{batch?.training_venue?.join(", ") || "-"}</td>
                                </tr>
                                <tr>
                                    <th>Test date:</th>
                                    <td>{batch?.test_date || "-"}</td>
                                </tr>
                                <tr>
                                    <th>Status:</th>
                                    <td>
                                        {batch?.status ? (
                                            <Badge bg="primary">
                                                {batch?.status}
                                            </Badge>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Header>

            {loading && <div>Loading...</div>}
            {/* {console.log(batch)} */}
            {batch.enrolment && (
                <AllocatedStudents
                    batch={batch}
                    columns={batchAllocatedStudentTableColumns}
                    data={batch.enrolment}
                    setRerender={setRerender}
                    rerender={rerender}
                    setNotif={setNotif}
                />
            )}

            <Card.Body className="pt-0">
                <Col md="10">
                    <h1 className="h3 mb-3">Candidates</h1>
                </Col>
                <>
                    {selectedFlatRows.length > 0 && (
                        <>
                            <hr />
                            <Row>
                                {selectedBatchName === "" && (
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={addDefaultOption(
                                                    batchListArray
                                                )}
                                                onChange={(e) => {
                                                    setSelectedBatchId(
                                                        e?.value
                                                    );
                                                }}
                                                disabled={isSubmitting}
                                                name="batch"
                                                isClearable={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col md={6}>
                                    {!addingStudents && (
                                        <>
                                        <Button
                                            variant="info"
                                            onClick={addToBatch}
                                            className="mb-2"
                                            disabled={addingStudents}
                                        >
                                            Add student to class/batch{" "}
                                            {selectedBatchName !== "" &&
                                                " - " + selectedBatchName}
                                        </Button>
                                        </>
                                    )}
                                    
                                    {addingStudents && (
                                        <div className="my-3">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                    {data.length === 0 && (
                        <Alert className="my-3" variant="warning">
                            <div className="alert-message">
                                No new enrolment list
                            </div>
                        </Alert>
                    )}
                    {data.length > 0 && (
                        <Row>
                            <Table
                                responsive
                                striped
                                bordered
                                {...getTableProps()}
                                size="md"
                            >
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row) => {
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(
                                                    (cell, index) => {
                                                        if (index === 1) {
                                                            return (
                                                                <td key={index}>
                                                                    {parseInt(
                                                                        cell.row
                                                                            .id
                                                                    ) + 1}
                                                                </td>
                                                            );
                                                        }
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Row>
                    )}
                </>
            </Card.Body>
        </React.Fragment>
    );
};

export default PendingStudentList;
