import React from "react";

import { Button, Modal } from "react-bootstrap";

export const ConfirmModal = ({ modalInfo, setModalInfo, api, refreshApi = null}) => {
    const closeModal = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
        });
        if(modalInfo?.refresh){
            refreshApi()
        }
    };

    const callApi = () => {
        if(modalInfo?.refresh){
            refreshApi()
        }else{
            api(modalInfo.values);
        }
        closeModal();
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size={modalInfo?.size ?? 'sm'}
            centered
            backdrop={modalInfo?.isStatic ? 'static' : true}
            keyboard={modalInfo?.isStatic}
        >
            <Modal.Body className="text-center m-3">
                <div className="mb-0">{modalInfo.notifMsg}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Close
                </Button>{" "}
                <Button variant={modalInfo.severity} onClick={() => callApi()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
