import React, {useState, useEffect} from "react";
import {
    beforeGSTAmount,
    calculateCommonBasedBeforeGSTSubtotal,
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    roundToTwoDecimalDigits,
} from "../../../../../utils/utility";
import { Table } from "react-bootstrap";

const QuotationCourseList = ({ quotation }) => {

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const totalTaxPercentage = quotation?.no_gst
        ? "0"
        : parseFloat(quotation?.gst).toFixed(0);
        
    const renderCourseInformation = (item) => {
        if (item.item_type === "discount") {
            return <>{item.discount_item_name}</>;
        }
        
        return (
            <>
                {item?.course?.name}
                <br />
                <tr>
                    <b>Item code :</b>
                    <td width="10%"></td>
                    <td>
                        {item.course_code?.course_code}
                    </td>
                </tr>
                <tr>
                    <b className="text-nowrap">Trade category :</b>
                    <td width="10%"></td>
                    <td>
                    {item?.trade_category}
                    </td>
                </tr>
                <tr>
                    <b className="text-nowrap">Type of trade :</b>
                    <td width="10%"></td>
                    <td>
                    {item?.type_of_trade}
                    </td>
                </tr>
                <tr>
                    <b>Application option :</b>
                    <td width="10%"></td>
                    <td>
                    {item?.application_option}
                    </td>
                </tr>
                {
                    item?.course_students?.length > 0 && (
                        <>
                            <tr>
                                <b>Students :</b>
                                <td width="10%"></td>
                                <td>
                                { item?.course_students.map(
                                    (data, index) => {
                                        return (
                                            <>
                                                {(data?.student_name || data?.fin) ? (
                                                    <>
                                                        {data?.student_name+ ' ' + data?.fin.slice(0, -4).replace(/./g, '*') + data?.fin.slice(-4)}<br></br>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {data?.student?.name+ ' ' + data?.student?.fin.slice(0, -4).replace(/./g, '*') + data?.student?.fin.slice(-4)}<br></br>
                                                    </>
                                                )}
                                            </>
                                        )
                                            
                                    }
                                )}
                                </td>
                            </tr>
                        <br></br>
                        </>
                    )
                }
                {/* {
                    item?.student_names !== "" && item?.student_names && (
                        <>
                            <tr>
                                <b>Students :</b>
                                <td width="10%"></td>
                                <td>
                                    {item?.student_names
                                    .split(",")
                                    .map((name, nameIndex) => (
                                        <div key={nameIndex}>
                                            {name}
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    {item?.student_names
                                    .split(",")
                                    .map((name, nameIndex) => (
                                        <div key={nameIndex}>
                                            {name}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        <br></br>
                        </>
                    )
                } */}
            </>
        );
    };

    return (
        <>
            <Table className="table" responsive>
                <thead>
                    <tr className="font-weight-bold">
                        <th width="5%">#</th>
                        <th width="50%">Course information</th>
                        <th width="10%">Student</th>
                        <th width="10%">Price</th>
                        <th width="8%">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {quotation?.sales_quotation_course?.length > 0 &&
                        quotation?.sales_quotation_course?.map(
                            (item, index) => (
                                <tr key={index}>
                                    <td width="5%">{index + 1}</td>
                                    <td>
                                        {item.item_type === "course" &&
                                            renderCourseInformation(item)}
                                        {item.item_type === "discount" &&
                                            item.discount_item_name}
                                    </td>
                                    <td>{item.total_student}</td>
                                    <td className="currency text-nowrap">
                                        {
                                            formatNumberWithCommas(
                                            roundToTwoDecimalDigits(
                                                beforeGSTAmount(
                                                    item.price,
                                                    quotation?.gst,
                                                    quotation?.no_gst
                                                )
                                            ))
                                        }
                                        {
                                            !quotation?.no_gst && 
                                            <>
                                                <br />
                                                <small>
                                                    After GST ${item.price}
                                                </small>
                                            </>
                                        }
                                    </td>
                                    <td className="currency text-nowrap">
                                        {formatNumberWithCommas(
                                            roundToTwoDecimalDigits(
                                                calculateCommonBasedBeforeGSTSubtotal(
                                                    item.price,
                                                    quotation?.gst,
                                                    item.total_student,
                                                    quotation?.no_gst
                                                )
                                            )
                                        )}
                                    </td>
                                </tr>
                            )
                        )}
                    {width < 485 ? (
                        <tr>
                            <td className="border-0"></td>
                            <td colSpan={1} className="text-end fs-4 border-0">
                                Quotation subtotal
                            </td>
                            <td className="currency border-0 text-right"></td>
                            <td className=" fs-4 border-0">
                                {formatNumberWithCommas(quotation?.subtotal)}
                            </td>
                            <td className="border-0"></td>
                        </tr>
                    ) : width < 1330 ? (
                        <>
                            <tr>
                                <td colSpan="3" className="text-end fs-4 border-0">
                                    Quotation subtotal
                                </td>
                                <td colSpan="2" className="currency fs-4 border-0 text-end">
                                    {formatNumberWithCommas(quotation?.subtotal)}
                                </td>
                            </tr>
                        </>
                    ): (
                        <tr>
                            <td colSpan="4" className="text-end fs-4 border-0">
                                Quotation subtotal
                            </td>
                            <td className=" currency fs-4 border-0">
                                {formatNumberWithCommas(quotation?.subtotal)}
                            </td>
                        </tr>
                    )}
                    {width < 485 ? 
                        (
                        <tr>
                            <td className="border-0"></td>
                            <td colSpan={1} className="text-end fs-4 border-0">
                                Total before tax
                            </td>
                            <td className="currency border-0 text-right"></td>
                            <td className=" fs-4 border-0">
                                {formatNumberWithCommas(quotation?.total_amount - quotation?.gst_amount)}
                            </td>
                            <td className="border-0"></td>
                        </tr>
                    )   :  width < 1330 ? 
                    (
                        <>
                            <tr>
                                <td colSpan="3" className="text-end fs-4 border-0">
                                    Total before tax
                                </td>
                                <td className="currency fs-4 border-0 text-end" colSpan="2">
                                    {formatNumberWithCommas(quotation?.total_amount - quotation?.gst_amount)}
                                </td>
                            </tr>

                        </>
                    )   : 
                    (
                         <tr>
                            <td colSpan="4" className="text-end fs-4 border-0">
                                Total before tax
                            </td>
                            <td className=" currency fs-4 border-0">
                                {formatNumberWithCommas(quotation?.total_amount - quotation?.gst_amount)}
                            </td>
                        </tr>
                    )}
                    {
                        width < 485 ? (
                            <tr>
                                <td className="border-0"></td>
                                <td colSpan="1" className="text-end fs-4 border-0 text-nowrap">
                                    Total tax amount GST {quotation?.gst}%
                                    {quotation?.no_gst && (
                                        <>
                                            <br />
                                            <small>Applied no GST</small>
                                        </>
                                    )}
                                </td>
                                <td className="currency border-0 text-right"></td>
                                <td className=" fs-4 border-0">
                                    {formatNumberWithCommas(quotation?.gst_amount)}
                                </td>
                                <td className="border-0"></td>
                            </tr>
                        ) : width < 1330 ? (
                        <>
                            <tr>
                                <td colSpan="3" className="text-end fs-4 border-0 text-nowrap">
                                    Total tax amount GST {quotation?.gst}%
                                    {quotation?.no_gst && (
                                        <>
                                            <br />
                                            <small>Applied no GST</small>
                                        </>
                                    )}
                                </td>
                                <td className="currency fs-4 border-0 text-end" colSpan="2">
                                    {formatNumberWithCommas(quotation?.gst_amount)}
                                </td>
                            </tr>
                        </>
                    )   : (
                        <tr>
                            <td colSpan="4" className="text-end fs-4 border-0 text-nowrap">
                                Total tax amount GST {quotation?.gst}%
                                {quotation?.no_gst && (
                                    <>
                                        <br />
                                        <small>Applied no GST</small>
                                    </>
                                )}
                            </td>
                            <td className="currency fs-4 border-0">
                                {formatNumberWithCommas(quotation?.gst_amount)}
                            </td>
                        </tr>
                    )}
                    {
                    width < 485 ? (
                        <tr>
                            <td className="border-0"></td>
                            <td colSpan="1" className="  text-end fs-4 border-0">
                                Total amount
                            </td>
                            <td className="currency border-0 text-right"></td>
                            <td className=" fs-4 border-0">
                                {formatNumberWithCommas(quotation.total_amount)}
                            </td>
                            <td className="border-0"></td>
                        </tr>
                    ) : width < 1330 ? (
                        <>
                            <tr>
                                <td colSpan="3" className="text-end fs-4 border-0">
                                    Total amount
                                </td>
                                <td className="currency fs-4 border-0 text-end" colSpan="2">
                                    {formatNumberWithCommas(quotation.total_amount)}
                                </td>
                            </tr>
                        </>
                    )     : (
                        <tr>
                            <td colSpan="4" className="  text-end fs-4 border-0">
                                Total amount
                            </td>
                            <td className=" currency fs-4 border-0">
                                {formatNumberWithCommas(quotation.total_amount)}
                            </td>
                        </tr>
                    )}
                    
                </tbody>
            </Table>
        </>
    );
};

export default QuotationCourseList;
