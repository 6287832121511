import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import BatchDetails from "../CommonComponents/BatchDetails";
import AttendanceBatchStudent from "./AttendanceBatchStudent";
import api from "../../../service/api";
import { tableAMColumns, tableEVColumns, tablePMColumns } from "./data";
import { ArrowLeft } from "react-feather";
const Attendance = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [classStudents, setClassStudents] = useState([]);
    const [attendanceDate, setAttendanceDate] = useState({
        date: "",
        session: "",
    });
    const [batch, setBatch] = useState({});
    const [loading, setLoading] = useState(false);
    const [defaultAccordion, setDefaultAccordion] = useState();

    const attendanceApi = async (id, session, date, accordianMenu) => {
        setLoading(true);
        const response = await api.get(
            `tms/batches/${id}/allocated-students?date=${date}&session=${session}`,
            {}
        );
        setClassStudents(response.data);
        setAttendanceDate({
            date: date,
            session: session,
        });
        setDefaultAccordion(accordianMenu);
        setLoading(false);
    };
    const getBatch = useCallback(async () => {
        const response = await api.get(`tms/batches/${id}`, {});
        setBatch(response.data);
    }, [id]);

    useEffect(() => {
        setLoading(true);
        getBatch();
        setLoading(false);
    }, [getBatch]);

    return (
        <React.Fragment>
            <Helmet title="Class attendance" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">Class - Attendance </h1>
                    </Col>
                    <Col md={6} className="text-end">
                        <Button
                            className="m-2"
                            onClick={() => navigate("/tms/batches/attendance")}
                        >
                            <ArrowLeft className="feather" />
                        </Button>
                    </Col>
                </Row>
                {loading && <div>Loading...</div>}
                {!loading && (
                    <Row>
                        <Col md="4" xl="3">
                            <BatchDetails
                                batch={batch}
                                type="attendance"
                                attendanceApi={attendanceApi}
                                defaultAccordion={defaultAccordion}
                            />
                        </Col>
                        {console.log(classStudents)}
                        <Col md="8" xl="9">
                            <AttendanceBatchStudent
                                data={classStudents}
                                columns={
                                    attendanceDate?.session?.toLowerCase() ===
                                    "am"
                                        ? tableAMColumns : 
                                        (attendanceDate?.session?.toLowerCase() == 'pm' ? tablePMColumns : tableEVColumns)
                                }
                                batch={batch}
                                attendanceDate={attendanceDate}
                                getBatchStudentsApi={attendanceApi}
                                defaultAccordion={defaultAccordion}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
};

export default Attendance;
